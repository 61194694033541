import config from './../config/config.js'


const rutaApi = config.rutaApis+'/ivas'



const listaIvas = () => {
  return fetch(rutaApi+'/listaIvas', {
    method: 'GET'
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}


export {
  listaIvas
}