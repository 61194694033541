import React, { Fragment, useState, useEffect } from "react";

import { useStore } from 'react-create-use-store';
import store from './../../adderStore.js'

import { withTranslation } from 'react-i18next'

import 'react-toastify/dist/ReactToastify.css';

import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import TableSortLabel from "@mui/material/TableSortLabel";

import ThemeGeroa from './../../ThemeGeroa.js'

import BotonFecha from "./../../components/BotonFecha.js";
import BotonXlsx from "./../../components/BotonXlsx.js";
import Lista from "./../../components/Lista.js";
import MensajeCabecera from "./../../components/MensajeCabecera.js";

import { ventasPorProductos } from './../../apis/api-pedido.js'

import { FechaDdMmAAAA, FechaAaaaMmDd, RedondeaPrecio } from "./../../config/funcionesPropias.js";
import { DataObject } from "@mui/icons-material";


const InfProductos = ({ t, ...props }) => {

    //const jwt = auth.isAuthenticated()
    //console.log('autenticado = ', jwt)
    const { state, actions } = useStore(store);

    // Arrays necesarios en varios submódulos
    const tipoInforme = [
        t("MisInformes.Todos"),
        t("MisInformes.SinPreparar"),
        t("MisInformes.Preparados"),
        t("MisInformes.Servidos")
    ]

    const agrupandoArticulos = [
        t("MisInformes.Desglosado"),
        t("MisInformes.Agrupando")
    ]

    var date = new Date()
    /*
    // Prepara las posibilidades de las capturas de Mercados
    var listaMercados = []
    for (let i = 0; i < state.mercadosProveedor.length; i++) {
        if (i === 0) {
            listaMercados.push(t("MisInformes.Todos"))
        }
        listaMercados.push(
            state.idioma === "Es" ? state.mercadosProveedor[i].MerDesC : state.mercadosProveedor[i].MerDesE
        )
    }
    */
    const [estado, setEstado] = useState(
        {
            //carga: true,
            informe: 0,
            agrupacion: 1,
            fechaInicio: new Date(date.getFullYear(), date.getMonth(), 1),
            fechaFin: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            mercado: undefined,
            seleccionMercados: [],
            lineasPedidos: [],
            lineasExcel: [],
            lineasAMostrar: [],
            mercadoSeleccionado: 0
        }
    )


    /*
    useEffect(() => {
        loadLineasPedidosIniciales()
    }, [estado.mercadoSeleccionado])
    */

    // Carga Inicial cuando se forma state. //
    useEffect(() => {
        //console.log("CargaMercados", state.idioma)
        if (state.mercadosProveedor.length > 0) {
            // Prepara las posibilidades de las capturas de Mercados
            var listaMercados = [t("MisInformes.Todos")]
            state.mercadosProveedor.forEach(mercado => {
                listaMercados.push(state.idioma === "Es" ? mercado.MerDesC : mercado.MerDesE)
            });
            /*
            var listaMercados = []
            for (let i = 0; i < state.mercadosProveedor.length; i++) {
                if (i === 0) {
                    listaMercados.push(t("MisInformes.Todos"))
                }
                listaMercados.push(
                    state.idioma === "Es" ? state.mercadosProveedor[i].MerDesC : state.mercadosProveedor[i].MerDesE
                )
            }
            */
            let mer = estado.mercado === undefined ? 0 : estado.mercado
            setEstado(estado => ({ ...estado, mercado: mer, seleccionMercados: listaMercados }))
        } else {
            actions.cambiarMensaje(t("FechasEntrega.NoHayMercados"))
        }
    }, [state.mercadosProveedor, state.idioma, estado.mercado, actions, t])



    useEffect(() => {
        //if (estado.carga) {
        loadLineasPedidosIniciales()
        // Actualiza el estado sin Repintar. //
        //    estado.carga = false
        //}

        // Estados que al cambiar obligan a volver a cargar los datos. //
    }, [
        estado.informe,
        estado.agrupacion,
        estado.fechaInicio,
        estado.fechaFin,
        estado.mercado,
        state.idioma
    ])



    //////////////////////////////////
    /// Filtrar Desde-Hasta Fechas ///
    //////////////////////////////////
    function actualizadorFechaDesde(fecha) {
        estado.fechaInicio = fecha
        setEstado((estado) => ({ ...estado, fechaInicio: fecha }))
        //loadLineasPedidosIniciales()
    }

    function actualizadorFechaHasta(fecha) {
        estado.fechaFin = fecha
        setEstado((estado) => ({ ...estado, fechaFin: fecha }))
        //loadLineasPedidosIniciales()
    }


    /////////////////////////////////////
    /// Selecciona filtro por mercado ///
    /////////////////////////////////////
    const handleFiltroMercado = event => {
        estado.mercado = event.target.value
        let mercado = estado.mercado === 0 ? 0 : state.mercadosProveedor[estado.mercado - 1].MerCod
        setEstado({ ...estado, mercadoSeleccionado: mercado })
        console.log("MercadoSeleccionado: ", mercado)
        console.log("MercadoSeleccinado en estado: ", estado.mercadoSeleccionado)
        // Fuerza Renderizado
        // loadLineasPedidosIniciales()
    }


    //////////////////////////////////////////////
    /// Selecciona filtro por tipo de Informe. ///
    //////////////////////////////////////////////
    const handleFiltroInforme = event => {
        console.log("Filtro Informe: ", event.target.value)
        estado.informe = event.target.value
        // Fuerza Renderizado
        setEstado({ ...estado })
        //loadLineasPedidosIniciales()
    }

    ////////////////////////////////////////////////
    /// Selecciona filtro por tipo de Agrupando ///
    ////////////////////////////////////////////////
    const handleFiltroAgrupandoArticulos = event => {
        estado.agrupacion = event.target.value
        // Fuerza Renderizado
        setEstado({ ...estado })
        //loadLineasPedidosIniciales()
    }


    // Contar los productos para establecer el calendario
    async function loadLineasPedidosIniciales() {
        // Vacia lo que podríamos tener previamente
        if (estado.lineasPedidos.length > 0) {
            setEstado({ ...estado, lineasPedidos: [], lineasExcel: [] })
            actions.cambiarMensaje("")
        }
        if (estado.lineasAMostrar.length > 0) {
            setEstado({ ...estado, lineasPedidos: [], lineasExcel: [] })
            actions.cambiarMensaje("")
        }
        //
        let mercadosFiltro
        if (estado.mercado !== undefined && state.mercadosProveedor.length > 0) {
            mercadosFiltro = estado.mercado === 0 ? "TODOS" : state.mercadosProveedor[estado.mercado - 1].MerCod
            ///ventasPorProducto/:proveedor/:esProductor/:mercado/:diaDesde/:diaHasta/:orden
            console.log("Mercado seleccionado antes de llamada: ", estado.mercadoSeleccionado)
            await ventasPorProductos({ proveedor: state.codProveedor, esProductor: state.esProductor ? "1" : "0", mercado: estado.mercadoSeleccionado, diaDesde: FechaAaaaMmDd(estado.fechaInicio), diaHasta: FechaAaaaMmDd(estado.fechaFin), agrupado: estado.agrupacion, estadoProducto: estado.informe }).then((data) => {
                if (data.error) {
                    console.log(data.error)
                }
                else {
                    if (data.length > 0) {
                        // Viene ordenado por Fecha Entrega +  Mercado + Nombre del producto
                        // Agrupar por esos 3 campos
                        let elemento = ''
                        let data1 = []
                        //console.log("data", data)
                        // Desglosado: Ordenado por Fecha, Mercado, Producto, Lote y Precio. //
                        if (estado.agrupacion === 0) {
                            data.forEach((l, i) => {
                                data1.push({
                                    "FechaPedido": l.fecha_pedido,
                                    "FechaEntrega": l.fecha_entrega,
                                    "CodigoMercado": l.mercado,
                                    "Mercado": state.idioma === 'Es' ? l.nombre_mercado_castellano : l.nombre_mercado_euskera,
                                    "CodigoProducto": l.codigo_producto,
                                    "NombreProducto": state.idioma === 'Es' ? l.descripcion_castellano : l.descripcion_euskera,
                                    "Lote": l.lote_pedido,
                                    "CantidadPedida": l.cantidad_pedida,
                                    "CantidadEntregada": l.cantidad_entregada,
                                    "CantidadServida": l.cantidad_servida,
                                    "Unidad": state.uniAVer[l.unidad_medida],
                                    "Precio": l.precio_unitario,
                                    "Importe": l.importe_servido
                                })
                            })
                            console.log("Data1: ", data1)
                        }
                        // Agrupado. Ordenado por Mercado, Producto, Lote y Precio. //                        
                        else {
                            data.forEach((l, i) => {
                                data1.push({
                                    // Solo poner el mercado si se seleccionan todos los mercados
                                    "Mercado": state.idioma === 'Es' ? l.nombre_mercado_castellano : l.nombre_mercado_euskera,
                                    "Productor": l.nombre_productor,
                                    "CodigoProducto": l.codigo_producto,
                                    "NombreProducto": state.idioma === 'Es' ? l.descripcion_castellano : l.descripcion_euskera,
                                    "FamiliaProducto": state.idioma === 'Es' ? l.familia_castellano : l.familia_euskera,
                                    "Precio": l.precio_unitario,
                                    "Unidad": l.unidad_medida,
                                    "NumeroPedidos": l.numero_de_pedidos,
                                    "CantidadPedida": l.cantidad_pedida,
                                    "CantidadEntregada": l.cantidad_entregada,
                                    "CantidadServida": l.cantidad_servida,
                                    "Diferencia": l.diferencia_servida_pedida,
                                    "ImporteServido": l.importe_servido,
                                    "MediaPrecio": l.media_precio,
                                    "MediaCantidadPedida": l.media_cantidad_pedida,
                                    "MediaImportePedido": l.media_importe_pedido,
                                    "Existencias": l.existencias
                                })
                            })
                        }
                        data = data1
                    }
                    console.log("Data: ", data)
                    setTimeout(() => {
                        setEstado({ ...estado, lineasAMostrar: data })
                    }, 100)
                    preparaVisualizacionYExcel(data)
                }
            })
        }
    }

    useEffect(() => {
        console.log("Lineas A Mostrar: ", estado.lineasAMostrar)
    }, [estado.lineasAMostrar])

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("CodigoProducto");

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    if (estado.lineasAMostrar) {

    }
    const sortedData = [...estado.lineasAMostrar].sort((a, b) => {
        if (a[orderBy] < b[orderBy]) {
            return order === "asc" ? -1 : 1;
        }
        if (a[orderBy] > b[orderBy]) {
            return order === "asc" ? 1 : -1;
        }
        return 0;
    });





    // Carga de datos Inicial --> despues de la carga renderiza
    function preparaVisualizacionYExcel(data) {
        // 0 es desglosado
        if (estado.agrupacion === 0) {
            prepVisExcelDesglosado(data)
        } else {
            prepVisExcelAgrupado(data)
        }
    }

    const traducirUnidad = (unidad) => {
        if(unidad === "0"){
            return " ud."
        } else{
            return " kg."
        }
    }



    function prepVisExcelAgrupado(data) {
        if (data.length > 0) {
            // Reorganiza las lineas
            let resultado = []
            let resultadoExcel = []
            let cabeceraExcel = [
                t("MisInformes.Mercado"),
                t("MisInformes.Producto"),
                t("MisInformes.Lote"),
                t("MisInformes.NumeroPedidos"),
                t("MisInformes.CantidadPedida"),
                t("MisInformes.Unidad"),
                t("MisInformes.CantidadEntregada"),
                t("MisInformes.CantidadServida"),
                t("MisInformes.Precio"),
                t("MisInformes.ImporteServido")
            ]
            //
            for (let i = 0; i < data.length; i++) {
                resultado.push({
                    Mercado: data[i].Mercado,
                    Producto: data[i].NombreProducto + "(" + data[i].CodigoProducto + ")",
                    Lote: "",
                    NumeroPedidos: data[i].NumeroPedidos,
                    CantidadPedida: data[i].CantidadPedida,
                    CantidadEntregada: data[i].CantidadEntregada,
                    CantidadServida: data[i].CantidadServida,
                    Unidad: traducirUnidad(data[i].Unidad),
                    Precio: data[i].Precio,
                    Importe: data[i].ImporteServido
                })
                resultadoExcel.push({})
                resultadoExcel[i][cabeceraExcel[0]] = data[i].Mercado
                resultadoExcel[i][cabeceraExcel[1]] = data[i].NombreProducto + "(" + data[i].CodigoProducto + ")"
                resultadoExcel[i][cabeceraExcel[2]] = data[i].Lote
                resultadoExcel[i][cabeceraExcel[3]] = data[i].NumeroPedidos
                resultadoExcel[i][cabeceraExcel[4]] = data[i].CantidadPedida
                resultadoExcel[i][cabeceraExcel[5]] = traducirUnidad(data[i].Unidad)
                resultadoExcel[i][cabeceraExcel[6]] = data[i].CantidadEntregada
                resultadoExcel[i][cabeceraExcel[7]] = data[i].CantidadServida
                resultadoExcel[i][cabeceraExcel[8]] = data[i].Precio
                resultadoExcel[i][cabeceraExcel[9]] = data[i].ImporteServido
            }
            // estado.lineasPedidos = resultado
            estado.lineasExcel = resultadoExcel
            // Fuerza Renderizado
            setEstado({ ...estado, lineasPedidos: resultado, lineasExcel: resultadoExcel })
            actions.cambiarMensaje("")
        } else {
            actions.cambiarMensaje(t("MisInformes.NoHayLineasVenta"))
        }
    }



    function prepVisExcelDesglosado(data) {
        if (data.length > 0) {
            // Reorganiza las lineas
            let resultado = []
            let resultadoExcel = []
            let cabeceraExcel = [
                t("MisInformes.FechaEntrega"),
                t("MisInformes.Mercado"),
                t("MisInformes.Producto"),
                t("MisInformes.Lote"),
                t("MisInformes.NumeroPedidos"),
                t("MisInformes.CantidadPedida"),
                t("MisInformes.Unidad"),
                t("MisInformes.CantidadEntregada"),
                t("MisInformes.CantidadServida"),
                t("MisInformes.Precio"),
                t("MisInformes.ImporteServido")
            ]
            //
            for (let i = 0; i < data.length; i++) {
                resultado.push({
                    Fecha: FechaDdMmAAAA(new Date(data[i].FechaEntrega)),
                    Mercado: data[i].Mercado,
                    Producto: data[i].NombreProducto + "(" + data[i].CodigoProducto + ")",
                    Lote: data[i].Lote,
                    NumeroPedidos: "1",
                    CantidadPedida: data[i].CantidadPedida,
                    CantidadEntregada: data[i].CantidadEntregada,
                    CantidadServida: data[i].CantidadServida,
                    Unidad: traducirUnidad(data[i].Unidad),
                    Precio: data[i].Precio,
                    Importe: data[i].ImporteServido
                })
                resultadoExcel.push({})
                resultadoExcel[i][cabeceraExcel[0]] = FechaDdMmAAAA(new Date(data[i].FechaEntrega))
                resultadoExcel[i][cabeceraExcel[1]] = data[i].Mercado
                resultadoExcel[i][cabeceraExcel[2]] = data[i].NombreProducto + "(" + data[i].CodigoProducto + ")"
                resultadoExcel[i][cabeceraExcel[3]] = data[i].Lote
                resultadoExcel[i][cabeceraExcel[4]] = data[i].NumeroPedidos
                resultadoExcel[i][cabeceraExcel[5]] = data[i].CantidadPedida
                resultadoExcel[i][cabeceraExcel[6]] = traducirUnidad(data[i].Unidad)
                resultadoExcel[i][cabeceraExcel[7]] = data[i].CantidadEntregada
                resultadoExcel[i][cabeceraExcel[8]] = data[i].CantidadServida
                resultadoExcel[i][cabeceraExcel[9]] = data[i].Precio
                resultadoExcel[i][cabeceraExcel[10]] = data[i].Importe
            }
            // estado.lineasPedidos = resultado
            estado.lineasExcel = resultadoExcel
            // Fuerza Renderizado
            setEstado({ ...estado, lineasPedidos: resultado, lineasExcel: resultadoExcel })
            actions.cambiarMensaje("")
        } else {
            actions.cambiarMensaje(t("MisInformes.NoHayLineasVenta"))
        }
    }



    const tituloYFiltros = (
        <Fragment>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 1 / 3 }}>
                    <Typography variant="verde30">
                        {t("MisInformes.Productos")}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 2 / 3, display: 'flex' }}>
                    <Box>
                        <BotonFecha
                            nombre={t("MisInformes.Desde")}
                            fecha={FechaDdMmAAAA(estado.fechaInicio)}
                            actualizadorFecha={actualizadorFechaDesde}
                        />
                    </Box>
                    <Box>
                        <BotonFecha
                            margenI={5}
                            nombre={t("MisInformes.Hasta")}
                            fecha={FechaDdMmAAAA(estado.fechaFin)}
                            actualizadorFecha={actualizadorFechaHasta}
                        />
                    </Box>
                </Box>
                <Box sx={{ width: 1 / 3, display: 'flex', justifyContent: "flex-end" }}>
                    <Typography variant="verde15" sx={{ ml: 3 }}>
                        {t("MisInformes.Ver")}:
                    </Typography>
                    <Lista
                        id={"Inf"}
                        elementos={tipoInforme}
                        accion={handleFiltroInforme}
                        activos={estado.informe}
                    />
                </Box>
                <Box sx={{ ml: 3 }}>
                    <BotonXlsx data={estado.lineasExcel} filename={t("MisProductos.Productos")} />
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 3 / 3, display: 'flex', justifyContent: "flex" }}>
                    <Typography variant="verde15">
                        {t("MisInformes.Mercado")}:
                    </Typography>
                    {estado.mercado !== undefined &&
                        <Lista
                            id={"Mer"}
                            elementos={estado.seleccionMercados}
                            accion={handleFiltroMercado}
                            activos={estado.mercado}
                        />
                    }
                </Box>
                <Box sx={{ width: 1 / 3, display: 'flex', justifyContent: "flex-end" }}>
                    <Typography variant="verde15" sx={{ ml: 3 }}>
                        {t("MisInformes.Informe")}:
                    </Typography>
                    <Lista
                        id={"Art"}
                        elementos={agrupandoArticulos}
                        accion={handleFiltroAgrupandoArticulos}
                        activos={estado.agrupacion}
                    />
                </Box>
            </Box>
        </Fragment>
    );






    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Container sx={{ mt: 1.5 }}>
                {tituloYFiltros}
                <MensajeCabecera />

                {/* ✅ Aquí encerramos la expresión dentro de llaves */}
                {estado.lineasAMostrar && estado.lineasAMostrar.length > 0 && (
                    <Grid sx={{ mt: 0 }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="lineas de pedidos">
                                <TableHead>
                                    <TableRow>
                                        {/** Definir columnas dinámicamente según estado.agrupacion */}
                                        {(
                                            estado.agrupacion === 0
                                                ? [ // Desglosado
                                                    { id: "FechaPedido", label: t("MisInformes.FechaPedido") },
                                                    { id: "FechaEntrega", label: t("MisInformes.FechaEntrega") },
                                                    { id: "CodigoMercado", label: t("MisInformes.CodigoMercado") },
                                                    { id: "Mercado", label: t("MisInformes.Mercado") },
                                                    { id: "CodigoProducto", label: t("MisInformes.CodigoProducto") },
                                                    { id: "NombreProducto", label: t("MisInformes.NombreProducto") },
                                                    { id: "Lote", label: t("MisInformes.Lote") },
                                                    { id: "CantidadPedida", label: t("MisInformes.CantidadPedida"), numeric: true },
                                                    { id: "CantidadEntregada", label: t("MisInformes.CantidadEntregada"), numeric: true },
                                                    { id: "CantidadServida", label: t("MisInformes.CantidadServida"), numeric: true },
                                                    { id: "Unidad", label: t("MisInformes.Unidad") },
                                                    { id: "Precio", label: t("MisInformes.Precio"), numeric: true },
                                                    { id: "Importe", label: t("MisInformes.Importe"), numeric: true }
                                                ]
                                                : [ // Agrupado
                                                    { id: "Mercado", label: t("MisInformes.Mercado") },
                                                    { id: "Productor", label: t("MisInformes.Productor") },
                                                    { id: "CodigoProducto", label: t("MisInformes.CodigoProducto") },
                                                    { id: "NombreProducto", label: t("MisInformes.NombreProducto") },
                                                    { id: "FamiliaProducto", label: t("MisInformes.FamiliaProducto") },
                                                    { id: "Precio", label: t("MisInformes.Precio"), numeric: true },
                                                    { id: "Unidad", label: t("MisInformes.Unidad") },
                                                    { id: "NumeroPedidos", label: t("MisInformes.Pedidos"), numeric: true },
                                                    { id: "CantidadPedida", label: t("MisInformes.CantidadPedida"), numeric: true },
                                                    { id: "CantidadEntregada", label: t("MisInformes.CantidadEntregada"), numeric: true },
                                                    { id: "CantidadServida", label: t("MisInformes.CantidadServida"), numeric: true },
                                                    { id: "Diferencia", label: t("MisInformes.Diferencia"), numeric: true },
                                                    { id: "ImporteServido", label: t("MisInformes.ImporteServido"), numeric: true },
                                                    { id: "MediaPrecio", label: t("MisInformes.MediaPrecio"), numeric: true },
                                                    { id: "MediaCantidadPedida", label: t("MisInformes.MediaCantidadPedida"), numeric: true },
                                                    { id: "MediaImportePedido", label: t("MisInformes.MediaImportePedido"), numeric: true },
                                                    { id: "Existencias", label: t("MisInformes.Existencias"), numeric: true }
                                                ]
                                        ).map((column) => (
                                            <TableCell key={column.id} align={column.numeric ? "right" : "left"}>
                                                <TableSortLabel
                                                    active={orderBy === column.id}
                                                    direction={orderBy === column.id ? order : "asc"}
                                                    onClick={() => handleRequestSort(column.id)}
                                                >
                                                    <Typography variant="azul15">{column.label}</Typography>
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {sortedData.map((l, i) => (
                                        <TableRow key={`Prod${l.CodigoProducto}${i}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            {/** Se renderizan las celdas según las columnas dinámicas */}
                                            {(
                                                estado.agrupacion === 0
                                                    ? [
                                                        l.Mercado,
                                                        l.FechaPedido,
                                                        l.FechaEntrega,
                                                        l.CodigoMercado,
                                                        l.Mercado,
                                                        l.CodigoProducto,
                                                        l.NombreProducto,
                                                        l.Lote,
                                                        l.CantidadPedida,
                                                        l.CantidadEntregada,
                                                        l.CantidadServida,
                                                        traducirUnidad(l.Unidad),
                                                        l.Precio,
                                                        l.Importe
                                                    ]
                                                    : [
                                                        l.Mercado,
                                                        l.Productor,
                                                        l.CodigoProducto,
                                                        l.NombreProducto,
                                                        l.FamiliaProducto,
                                                        l.Precio,
                                                        traducirUnidad(l.Unidad),
                                                        l.NumeroPedidos,
                                                        l.CantidadPedida,
                                                        l.CantidadEntregada,
                                                        l.CantidadServida,
                                                        l.Diferencia,
                                                        l.ImporteServido,
                                                        l.MediaPrecio,
                                                        l.MediaCantidadPedida,
                                                        l.MediaImportePedido,
                                                        l.Existencias
                                                    ]
                                            ).map((value, index) => (
                                                <TableCell key={index} align={typeof value === "number" ? "right" : "left"}>
                                                    {value}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                )}

                <br />
            </Container>
        </ThemeProvider>
    );
}

export default withTranslation()(InfProductos)