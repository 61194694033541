import React, { Fragment, useState, useEffect } from "react";

import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { withTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider'

import PanelFichaProducto from "./PanelFichaProducto.js";
import ThemeGeroa from './../../ThemeGeroa.js'
//import auth from './../../apis/auth-helper.js'
import { countProductosProveedor, listaProductosProveedor } from './../../apis/api-producto.js'
import { listaMercadosProvCoor } from './../../apis/api-mercado.js'
import MensajeCabecera from "../../components/MensajeCabecera.js";
import Button from "@mui/material/Button";
import { FechaAaaaMmDdMmSs } from "../../config/funcionesPropias.js";

import Pagination from "@mui/material/Pagination";

import Lista from "../../components/Lista.js";
import { useStore } from 'react-create-use-store';
import store from './../../adderStore.js'
import Box from "@mui/material/Box";
import DialogProducto from './DialogProducto.js'
import DialogBusqueda from "./DialogBusqueda.js";


const MisProductos = ({ t, ...props }) => {
    //const jwt = auth.isAuthenticated()
    const { state, actions } = useStore(store);
    const [estado, setEstado] = useState(
        {
            carga: true,
            pagina: 0,
            numPaginas: 0,
            activos: 0,
            productos: []
        }
    )

    // Arrays necesarios en varios submódulos
    const productosAVer = [
        t("MisProductos.Visible"),
        t("MisProductos.Oculto")
    ]

    const uniAVer = [
        t("MisProductos.Ud"),
        t("MisProductos.Kg"),
        t("MisProductos.Lit")
    ]

    const un2AVer = [
        t("MisProductos.Ud"),
        t("MisProductos.Kg"),
        t("MisProductos.Lit")
    ]

    const arrProductos = { productosAVer, uniAVer, un2AVer }

    const [producto, setProducto] = useState({
        ProCod: '',
        ProDesC: '',
        ProDesE: '',
        ProFam: '001',
        ProSub: '',
        ProFEi: FechaAaaaMmDdMmSs(new Date()),
        ProEIn: '1',
        ProExi: '1',
        ProFac: '1',
        ProIva: '002',
        ProLot: '',
        ProUni: '0',
        ProUn2: '0',
        ProVis: '1',
        ProNPr: '',
        Mercados: []
    })

    // Constante cuantos = Productos a ver en una página
    const cuantos = 500

    /////////////////////////////////////
    /// Selecciona filtro por estado. ///
    /////////////////////////////////////
    const handleFiltro = event => {
        //estado.activos = event.target.value
        // Fuerza Renderizado
        //setEstado({ ...estado })
        //loadProductosIniciales()

        // Forzar renderizado y carga de productos iniciales. //
        setEstado(estado => ({ ...estado, activos: event.target.value, carga: true }))
    }

    const handlePagina = (event, value) => {
        estado.pagina = value
        // Cargar productos
        loadProductos(estado.pagina)
    }

    ////////////////////////////////
    ///    BUSCAR UN PRODUCTO    ///
    ////////////////////////////////
    let textoABuscar = ""
    const handleVolver = event => {
        textoABuscar = ""
        loadProductosIniciales()
    }


    const [openBuscar, setOpenBuscar] = useState(false)
    const handleBuscar = event => {
        //setOpenBuscar(!openBuscar)
        setOpenBuscar(openBuscar => !openBuscar)
    }

    function actualizadorBusqueda(hayQueBuscar, texto) {
        textoABuscar = ""
        if (hayQueBuscar) {
            textoABuscar = texto
            loadProductosIniciales()
            // El texto a buscar debería ser un gancho y habría que recargar cambiando el estado. //
            // ahora si utilizamos el gancho con estado.carga = true no Funciona bien.
        }
        //setOpenBuscar(!openBuscar)
        setOpenBuscar(openBuscar => !openBuscar)
    }


    ///////////////////////////////
    ///    CREAR UN PRODUCTO    ///
    ///////////////////////////////
    const [openCrear, setOpenCrear] = useState(false)
    const handleCrear = event => {
        setOpenCrear(openCrear => !openCrear)
    }

    function actualizadorAlta(p, g) {
        setProducto({
            ProCod: '',
            ProDesC: '',
            ProDesE: '',
            ProFam: '001',
            ProSub: '',
            ProFEi: FechaAaaaMmDdMmSs(new Date()),
            ProEIn: '1',
            ProExi: '1',
            ProFac: '1',
            ProIva: '002',
            ProLot: '',
            ProUni: '0',
            ProUn2: '0',
            ProVis: '1',
            ProNpr: '',
            Mercados: []
        })

        loadProductosIniciales()
        setOpenCrear(openCrear => !openCrear)
    }


    async function cargaMercadosProveedor() {
        // Cuenta los clientes para organizar la paginacion
        //console.log('en el api = ', { esProductor: state.esProductor ? "1" : "0", proveedor: state.codProveedor })
        await listaMercadosProvCoor({ esProductor: state.esProductor ? "1" : "0", proveedor: state.codProveedor }).then((dataM) => {
            //console.log("Mercados: ", dataM)
            const p = producto
            p.Mercados = dataM.map((m) => {
                // Añadir datos defecto. //
                m.TarVal = 0
                m.TarVis = 0
                m.TarNov = 0
                // Quitar datos innecesarios. //
                delete m.PrMMer
                delete m.PrMPro
                return m
            })
            setProducto({ ...p })
        })
    }

    useEffect(() => {
        // Si no hay Mercados del Proveedor. //
        if (producto.Mercados.length === 0) {
            cargaMercadosProveedor()
        }
    })

    // Contar los productos para establecer el número de paginas
    async function loadProductosIniciales() {
        // Cuenta los clientes para organizar la paginacion
        await countProductosProveedor({ proveedor: state.codProveedor, filtro: estado.activos === 0 ? 1 : 0 }, { texto: textoABuscar }).then((data) => {
            if (data.error) {
                // Ver todo lo que devuelve. //
                console.log(data)
            }
            else {
                let productosTotales = data[0].registrosProductos
                // Ponemos el Nº productos por página
                let num = Math.round(productosTotales / cuantos)
                if (productosTotales / cuantos > num) {
                    num++
                }
                if (num < 1) { num = 1 }
                // Paginas
                estado.numPaginas = num
                estado.pagina = 1
                // Carga los clientes
                loadProductos(estado.pagina)
            }
        })
    }

    // Carga de datos Inicial --> despues de la carga renderiza
    async function loadProductos(pagina) {
        // Calcula el registro del producto Inicial
        // ¡¡ COMIENZA EN EL 0 !!
        let desde = (cuantos * (pagina - 1))

        // Busca los productos en funcion del filtro:
        await listaProductosProveedor({ proveedor: state.codProveedor, filtro: estado.activos === 0 ? 1 : 0, desde: desde, cuantos: cuantos, idioma: state.idioma }, { texto: textoABuscar }).then((data) => {
            //console.log("state: ", state)
            //console.log("Data Productos: ", data)
            if (data.error) {
                console.log(data.error)
            }
            else {
                estado.productos = data
                // Fuerza Renderizado
                setEstado({ ...estado })
            }
        })
    }

    useEffect(() => {
        if (estado.carga && state.esProductor) {
            loadProductosIniciales()
            // No actualizar con setEstado porque entonces entra en //
            // bucle y se está recargando la pagina continuamente.  //
            estado.carga = false
        }

        // No incluir el array de dependencias vacío (,[]) para //
        // que sólo se ejecute una vez en el primer renderizado //
        // porque se debe ejecutar también si cambia el idioma. //
    })


    // Recargar si cambia el Idioma. //
    useEffect(() => {
        // Ordenar el Array de FamiliasSubfamilias por descripción. //
        let famSub = state.familiasSubfamilias
        famSub.sort( (a,b) => {
            let x, y
            if (state.idioma === "Es") {
                // Descripciones Cashtellano
                x = (a.FamDesC + a.SubDesC).toUpperCase()
                y = (b.FamDesC + b.SubDesC).toUpperCase()
            } else {
                // Descripciiones Euskera
                x = (a.FamDesE + a.SubDesE).toUpperCase()
                y = (b.FamDesE + b.SubDesE).toUpperCase()
            }
            if (x < y) return -1
            if (x > y) return 1
            return 0
        })
        // Guardar el array ordenado. //
        actions.cambiarFamiliasSubfamilias(famSub)

        //if (!estado.carga) {
        //    estado.carga = true
        setEstado(estado => ({ ...estado, carga: true }))
        //}
    }, [state.idioma, state.familiasSubfamilias, actions])


    const tituloYFiltros = (
        <div>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 2 / 6 }}>
                    <Typography variant="verde30">
                        {t("MisProductos.Productos")}
                    </Typography>
                </Box>
                <Box sx={{ mt: 2, width: 4 / 6, display: 'flex' }} justifyContent="flex-end">
                    <Button
                        variant="contained"
                        //startIcon={<SendIcon />}
                        sx={{ mt: -1, ml: 5, mr: 2, width: 100, height: 30 }}
                        onClick={handleVolver}
                    >
                        {t("MisProductos.Volver")}
                    </Button>
                    <Button
                        variant="contained"
                        //startIcon={<SendIcon />}
                        sx={{ mt: -1, ml: 2, mr: 7, width: 100, height: 30 }}
                        onClick={handleBuscar}
                    >
                        {t("MisProductos.Buscar")}
                    </Button>
                    <Typography variant="verde15">
                        {t("MisProductos.VerProductos")}:
                    </Typography>
                    <Lista
                        elementos={productosAVer}
                        accion={handleFiltro}
                        activos={estado.activos}
                    />
                    <Button
                        variant="contained"
                        disabled={(estado.activos !== 0)}
                        //startIcon={<SendIcon />}
                        sx={{ mt: -0.5, ml: 2, mr: 7, width: 90, height: 30 }}
                        onClick={handleCrear}
                    >
                        {t("MisProductos.Añadir")}
                    </Button>

                </Box>
            </Box>
        </div>
    )


    const listaProductos = (
        estado.productos.length >= 0 ?
            <Grid sx={{ mt: 0 }}>
                {/* PAGINACION */}
                {estado.productos.length > 0 && estado.numPaginas > 1 &&
                    <Grid container justifyContent="center" >
                        <Pagination
                            sx={{ mt: 0, mb: 1, ml: 10 }}
                            count={estado.numPaginas}
                            page={estado.pagina}
                            onChange={handlePagina} />
                    </Grid>
                }
                {openBuscar &&
                    <DialogBusqueda
                        actualizador={actualizadorBusqueda}
                    />}

                {/* DIALOGO PRODUCTO ALTA */}
                {openCrear &&
                    <DialogProducto
                        producto={producto}
                        tipo={"A"}
                        actualizador={actualizadorAlta}
                        arrP={arrProductos}
                    />}
                {estado.productos.map((linea, i) => (
                    <Grid item key={"pr" + linea.ProCod + i + estado.pagina}>
                        <Divider />
                        {/*linea.Mercados.find(item => item.visible === (estado.activos === 0 ? 1 : 0)) !== -1 ? "Hay" : "No hay"*/}
                        <PanelFichaProducto
                            key={"pfp" + linea.ProCod + i}
                            producto={linea}
                            arrProductos={arrProductos}
                            recargar={loadProductosIniciales}
                        />
                    </Grid>
                ))}
                {/* PAGINACION */}
                {estado.productos.length > 0 && estado.numPaginas > 1 &&
                    <Grid container justifyContent="center" sx={{ mt: 1 }}>
                        <Pagination
                            sx={{ mt: 0, ml: 10 }}
                            count={estado.numPaginas}
                            page={estado.pagina}
                            onChange={handlePagina} />
                    </Grid>
                }
            </Grid >
            :
            <Fragment />
    )


    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Container sx={{ mt: 1.5 }}>
                {tituloYFiltros}
                <MensajeCabecera />
                {listaProductos}
                <br />
            </Container>
        </ThemeProvider>
    );
}

export default withTranslation()(MisProductos)