import config from './../config/config.js'

const rutaApi = config.rutaApis + '/facturas'



///////////////////////////////
// Informe de Fcturas
const listaFacResProvCoor = (params) => {
  return fetch(rutaApi + '/listaFacResProvCoor/' + params.proveedor + '/' + params.esProductor + '/' + params.mercado + '/' + params.diaDesde + '/' + params.diaHasta, {
    method: 'GET',
  }).then((response) => {
    return response.json()
  }).catch((err) => console.log(err))
}



///////////////////////////////
// Informe de Lineas de Fcturas
const listaFacDesProvCoor = (params) => {
  return fetch(rutaApi + '/listaFacDesProvCoor/' + params.proveedor + '/' + params.esProductor + '/' + params.mercado + '/' + params.diaDesde + '/' + params.diaHasta, {
    method: 'GET',
  }).then((response) => {
    return response.json()
  }).catch((err) => console.log(err))
}


const descargaPdf = (params) => {
  return fetch(rutaApi + '/descargaPdf/' + params.factura, {
    method: 'GET',
  }).then((response) => {
    return response.blob()
  }).catch((err) => console.log(err))
}

export {
  listaFacResProvCoor,
  listaFacDesProvCoor,
  descargaPdf 
}