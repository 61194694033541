import config from './../config/config.js'

const rutaApi = config.rutaApis + '/mercados'


const listaMercados = () => {
  return fetch(rutaApi + '/listaMercados', {
    method: 'GET'
  }).then((response) => {
    return response.json()
  }).catch((err) => { console.log(err) })
}


const listaMercadosProvCoor = (params) => {
  return fetch(rutaApi + '/listaMercadosProvCoor/' + params.esProductor + '/' + params.proveedor, {
    method: 'GET'
  }).then((response) => {
    return response.json()
  }).catch((err) => { console.log(err) })
}

const detalleMercado = async (codigo) => {
  try {
    // Realizar la solicitud GET
    console.log("Ruta: ", rutaApi + `/${codigo}`)
    const response = await fetch(rutaApi + `/${codigo}`);
    
    
    // Comprobar si la respuesta es correcta (status 200)
    if (!response.ok) {
      throw new Error('Error al obtener los detalles del mercado');
    }

    // Convertir la respuesta en formato JSON
    const data = await response.json();
    console.log("data en detalleMercado: ", data)

    // Como esperas solo un registro, devolvemos el primer objeto del array
    return data;

  } catch (error) {
    console.error('Error al obtener los detalles del mercado:', error);
    // Retornar un objeto vacío o un valor adecuado para manejar el error en la aplicación
    return null;
  }
};


const getProductosMercado = async (codigo) => {
  try {
    // Realizar la solicitud GET para obtener los productos del mercado
    console.log("Ruta: ", rutaApi + `/productos/${codigo}`);
    const response = await fetch(rutaApi + `/${codigo}/productos`);
    
    // Comprobar si la respuesta es correcta (status 200)
    if (!response.ok) {
      throw new Error('Error al obtener los productos del mercado');
    }

    // Convertir la respuesta en formato JSON
    const data = await response.json();
    console.log("Productos obtenidos:", data);

    // Devolver el array de productos
    return data; // Ya que la respuesta es un array de productos

  } catch (error) {
    console.error('Error al obtener los productos del mercado:', error);
    // Retornar un array vacío o un valor adecuado para manejar el error en la aplicación
    return [];
  }
};


const listaFPagoMercados = (params) => {
  return fetch(rutaApi + '/listaFPagoMercados/' + params.mercado, {
    method: 'GET'
  }).then((response) => {
    return response.json()
  }).catch((err) => { console.log(err) })
}

export {
  listaMercados,
  listaMercadosProvCoor,
  listaFPagoMercados,
  detalleMercado,
  getProductosMercado
}