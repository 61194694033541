import React, { Fragment, useState, useEffect } from "react";

import { useStore } from 'react-create-use-store';
import store from './../../adderStore.js'

import { withTranslation } from 'react-i18next'

import 'react-toastify/dist/ReactToastify.css';

import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import TableSortLabel from "@mui/material/TableSortLabel";
import { ThemeProvider } from '@mui/material/styles';

import ThemeGeroa from './../../ThemeGeroa.js'

import BotonFecha from "./../../components/BotonFecha.js";
import BotonXlsx from "./../../components/BotonXlsx.js";
import Lista from "./../../components/Lista.js";
import MensajeCabecera from "./../../components/MensajeCabecera.js";

import { ventasPorProveedores } from './../../apis/api-pedido.js'

import { FechaDdMmAAAA, FechaAaaaMmDd, RedondeaPrecio } from "./../../config/funcionesPropias.js";


const InfProductosProductores = ({ t, ...props }) => {


    //const jwt = auth.isAuthenticated()
    //console.log('autenticado = ', jwt)
    const { state, actions } = useStore(store);

    // Arrays necesarios en varios submódulos
    const tipoInforme = [
        t("MisInformes.Todos"),
        t("MisInformes.SinPreparar"),
        t("MisInformes.Preparados"),
        t("MisInformes.Servidos")
    ]

    const agrupandoArticulos = [
        t("MisInformes.Desglosado"),
        t("MisInformes.Agrupando")
    ]

    var date = new Date()
    /*
    // Prepara las posibilidades de las capturas de Mercados
    var listaMercados = []
    for (let i = 0; i < state.mercadosProveedor.length; i++) {
        if (i === 0) {
            listaMercados.push(t("MisInformes.Todos"))
        }
        listaMercados.push(
            state.idioma === "Es" ? state.mercadosProveedor[i].MerDesC : state.mercadosProveedor[i].MerDesE
        )
    }
    */
    const [estado, setEstado] = useState(
        {
            //carga: true,
            informe: 0,
            agrupacion: 1,
            fechaInicio: new Date(date.getFullYear(), date.getMonth(), 1),
            fechaFin: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            mercado: undefined,
            seleccionMercados: [],
            proveedor: undefined,
            seleccionProveedores: [],
            lineasPedidos: [],
            lineasExcel: [],
            lineasAMostrar: [],
            mercadoSeleccionado: 0
        }
    )

    useEffect(() => {
        loadLineasPedidosIniciales()
    }, [])


    // Carga Inicial cuando se forma state. //
    useEffect(() => {
        //console.log("CargaMercados", state.idioma)
        if (state.mercadosProveedor.length > 0) {
            // Prepara las posibilidades de las capturas de Mercados
            var listaMercados = [t("MisInformes.Todos")]
            state.mercadosProveedor.forEach(mercado => {
                listaMercados.push(state.idioma === "Es" ? mercado.MerDesC : mercado.MerDesE)
            });
            //
            let mer = estado.mercado === undefined ? 1 : estado.mercado
            //
            //
            setEstado(estado => ({ ...estado, mercado: mer, seleccionMercados: listaMercados }))
        } else {
            actions.cambiarMensaje(t("FechasEntrega.NoHayMercados"))
        }
    }, [state.mercadosProveedor, state.idioma, estado.mercado, actions, t])



    useEffect(() => {
        //if (estado.carga) {
        loadLineasPedidosIniciales()
        // Actualiza el estado sin Repintar. //
        //    estado.carga = false
        //}

        // Estados que al cambiar obligan a volver a cargar los datos. //
    }, [
        estado.informe,
        estado.agrupacion,
        estado.fechaInicio,
        estado.fechaFin,
        estado.mercado,
        state.idioma
    ])



    //////////////////////////////////
    /// Filtrar Desde-Hasta Fechas ///
    //////////////////////////////////
    function actualizadorFechaDesde(fecha) {
        estado.fechaInicio = fecha
        setEstado((estado) => ({ ...estado, fechaInicio: fecha }))
        //loadLineasPedidosIniciales()
    }

    function actualizadorFechaHasta(fecha) {
        estado.fechaFin = fecha
        setEstado((estado) => ({ ...estado, fechaFin: fecha }))
        //loadLineasPedidosIniciales()
    }


    /////////////////////////////////////
    /// Selecciona filtro por mercado ///
    /////////////////////////////////////
    const handleFiltroMercado = event => {
        estado.mercado = event.target.value
        let mercado = estado.mercado === 0 ? 0 : state.mercadosProveedor[estado.mercado - 1].MerCod
        setEstado({ ...estado, mercadoSeleccionado: mercado })
        console.log("MercadoSeleccionado: ", mercado)
        console.log("MercadoSeleccinado en estado: ", estado.mercadoSeleccionado)
        // Fuerza Renderizado
        // loadLineasPedidosIniciales()
    }

    /////////////////////////////////////
    /// Selecciona filtro por proveedor ///
    /////////////////////////////////////
    const handleFiltroProveedor = event => {
        estado.proveedor = event.target.value
        // Cambia tambien el Array de excel

        // Fuerza Renderizado
        setEstado({ ...estado })
    }

    //////////////////////////////////////////////
    /// Selecciona filtro por tipo de Informe. ///
    //////////////////////////////////////////////
    const handleFiltroInforme = event => {
        estado.informe = event.target.value
        // Fuerza Renderizado
        setEstado({ ...estado })
        //loadLineasPedidosIniciales()
    }

    ////////////////////////////////////////////////
    /// Selecciona filtro por tipo de Agrupando ///
    ////////////////////////////////////////////////
    const handleFiltroAgrupandoArticulos = event => {
        estado.agrupacion = event.target.value
        // Fuerza Renderizado
        setEstado({ ...estado })
        //loadLineasPedidosIniciales()
    }


    // Contar los productos para establecer el calendario
    async function loadLineasPedidosIniciales() {
        // Vacia lo que podríamos tener previamente
        if (estado.lineasPedidos.length > 0) {
            setEstado({ ...estado, lineasPedidos: [], lineasExcel: [] })
            actions.cambiarMensaje("")
        }
        //
        let mercadosFiltro
        if (estado.mercado !== undefined && state.mercadosProveedor.length > 0) {
            mercadosFiltro = estado.mercado === 0 ? 0 : state.mercadosProveedor[estado.mercado - 1].MerCod
            console.log("Agrupacion antes de la llamada: ", estado.agrupacion)
            console.log("Informe antes de la llamada: ", estado.informe)
            await ventasPorProveedores({ proveedor: state.codProveedor, mercado: mercadosFiltro, diaDesde: FechaAaaaMmDd(estado.fechaInicio), diaHasta: FechaAaaaMmDd(estado.fechaFin), agrupacion: estado.agrupacion, tipoInforme: estado.informe }).then((data) => {
                console.log("Data: ", data)
                if (data.error) {
                    console.log(data.error)
                }
                else {
                    console.log("Data: ", data)
                    console.log("Ventas: ", data.ventas)
                    let data1 = []
                    if (data.proveedores.length > 0 && data.ventas.length > 0) {
                        // Obtener primero los campos proveedor distintos de los articulos (no el productor sino el campo proveedor)
                        let proDiferentes = [t("MisInformes.Todos")]
                        let pr = estado.proveedor === undefined ? 0 : estado.proveedor
                        //
                        estado.proveedor = pr
                        data.proveedores.forEach((p, j) => {
                            proDiferentes.push(p.nombre_proveedor)
                        })
                        estado.seleccionProveedores = proDiferentes
                        //
                        
                        //console.log("data", data)
                        // Desglosado: Ordenado por Fecha, Mercado, Producto, Lote y Precio. //
                        console.log("Ventas: ", data.ventas)
                        if (estado.agrupacion === 0) {
                            data.ventas.forEach((l, i) => {
                                data1.push({
                                    "FechaPedido": l.fecha_entrega.slice(0, 10),
                                    "FechaEntrega": l.fecha_entrega.slice(0, 10),
                                    "CodigoMercado": l.mercado,
                                    "Mercado": state.idioma === 'Es' ? l.nombre_mercado_castellano : l.nombre_mercado_euskera,
                                    "CodigoProveedor": l.codigo_proveedor,
                                    "Proveedor": l.nombre_proveedor,
                                    "NumeroPedido": l.numero_pedido,
                                    "Lote": l.lote_pedido,
                                    "NombreProducto": state.idioma === 'Es' ? l.descripcion_castellano : l.descripcion_euskera,
                                    "CantidadPedida": l.cantidad_pedida,
                                    "CantidadEntregada": l.cantidad_entregada,
                                    "CantidadServida": l.cantidad_servida,
                                    "Diferencia": l.cantidad_pedida - l.cantidad_servida,
                                    "Unidad": state.uniAVer[l.unidad_medida],
                                    "Precio": l.precio_unitario,
                                    "ImporteServido": l.cantidad_servida !== "" ? l.precio_unitario * l.cantidad_servida : 0,
                                    "CodigoProducto": l.codigo_producto
                                });
                            });
                        }
                        else {
                            console.log("Data en else (agrupando): ", data)
                            data.ventas.forEach((l, i) => {
                                data1.push({
                                    "Mercado": state.idioma === 'Es' ? l.nombre_mercado_castellano : l.nombre_mercado_euskera,
                                    "CodigoProveedor": l.codigo_proveedor,
                                    "Proveedor": l.nombre_proveedor,
                                    "NumeroPedidos": l.numero_de_pedidos,
                                    "CantidadPedida": l.cantidad_pedida_total,
                                    "CantidadEntregada": l.cantidad_entregada_total,
                                    "CantidadServida": l.cantidad_servida_total,
                                    "Diferencia": l.diferencia_servida_pedida,
                                    "MediaPrecio": l.media_precio_producto,
                                    "Importe": l.importe_servido_total + " €"
                                });
                            });
                        }
                    }
                    setTimeout(() => {
                        setEstado({ ...estado, lineasAMostrar: data1 })
                    }, 100)
                    preparaVisualizacionYExcel(data)
                }
            })
        }
    }


    useEffect(() => {
        console.log("Lineas A Mostrar: ", estado.lineasAMostrar)
    }, [estado.lineasAMostrar])

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("CodigoProducto");

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    if(estado.lineasAMostrar){
        
    }

    const sortedData = [...(estado.lineasAMostrar || [])].sort((a, b) => {
        if (a[orderBy] < b[orderBy]) {
            return order === "asc" ? -1 : 1;
        }
        if (a[orderBy] > b[orderBy]) {
            return order === "asc" ? 1 : -1;
        }
        return 0;
    });





    const mostrarColumnaMercado = estado.mercado !== 0; // Oculta la columna "Mercado" si se selecciona "TODOS"

    // Carga de datos Inicial --> despues de la carga renderiza
    function preparaVisualizacionYExcel(data) {
        // 0 es desglosado
        if (estado.agrupacion === 0) {
            prepVisExcelDesglosado(data)
        } else {
            prepVisExcelAgrupado(data)
        }
    }



    function prepVisExcelAgrupado(data) {
        if (data.length > 0) {
            // Reorganiza las lineas
            let resultado = []
            let resultadoExcel = []
            let cabeceraExcel = [
                t("MisInformes.Mercado"),
                t("MisInformes.Proveedor"),
                t("MisInformes.Producto"),
                t("MisInformes.Lote"),
                t("MisInformes.NumeroPedidos"),
                t("MisInformes.CantidadPedida"),
                t("MisInformes.Unidad"),
                t("MisInformes.CantidadEntregada"),
                t("MisInformes.CantidadServida"),
                t("MisInformes.Precio"),
                t("MisInformes.ImporteServido")
            ]
            //
            for (let i = 0; i < data.length; i++) {
                resultado.push({
                    Mercado: data[i].Mercado,
                    Proveedor: data[i].Proveedor,
                    Producto: data[i].NombreProducto + "(" + data[i].CodigoProducto + ")",
                    Lote: data[i].Lote,
                    NumeroPedidos: data[i].NumeroPedidos,
                    CantidadPedida: data[i].CantidadPedida,
                    CantidadEntregada: data[i].CantidadEntregada,
                    CantidadServida: data[i].CantidadServida,
                    Unidad: data[i].Unidad,
                    Precio: data[i].Precio,
                    Importe: data[i].Importe
                })
                resultadoExcel.push({})
                resultadoExcel[i][cabeceraExcel[0]] = data[i].Mercado
                resultadoExcel[i][cabeceraExcel[1]] = data[i].Proveedor
                resultadoExcel[i][cabeceraExcel[2]] = data[i].NombreProducto + "(" + data[i].CodigoProducto + ")"
                resultadoExcel[i][cabeceraExcel[3]] = data[i].Lote
                resultadoExcel[i][cabeceraExcel[4]] = data[i].NumeroPedidos
                resultadoExcel[i][cabeceraExcel[5]] = data[i].CantidadPedida
                resultadoExcel[i][cabeceraExcel[6]] = data[i].Unidad
                resultadoExcel[i][cabeceraExcel[7]] = data[i].CantidadEntregada
                resultadoExcel[i][cabeceraExcel[8]] = data[i].CantidadServida
                resultadoExcel[i][cabeceraExcel[9]] = data[i].Precio
                resultadoExcel[i][cabeceraExcel[10]] = data[i].Importe
            }
            estado.lineasPedidos = resultado
            estado.lineasExcel = resultadoExcel
            // Fuerza Renderizado
            setEstado({ ...estado, lineasPedidos: resultado, lineasExcel: resultadoExcel })
            actions.cambiarMensaje("")
        } else {
            actions.cambiarMensaje(t("MisInformes.NoHayLineasVenta"))
        }
    }



    function prepVisExcelDesglosado(data) {
        if (data.length > 0) {
            // Reorganiza las lineas
            let resultado = []
            let resultadoExcel = []
            let cabeceraExcel = [
                t("MisInformes.FechaEntrega"),
                t("MisInformes.Mercado"),
                t("MisInformes.Proveedor"),
                t("MisInformes.Producto"),
                t("MisInformes.Lote"),
                t("MisInformes.NumeroPedidos"),
                t("MisInformes.CantidadPedida"),
                t("MisInformes.Unidad"),
                t("MisInformes.CantidadEntregada"),
                t("MisInformes.CantidadServida"),
                t("MisInformes.Precio"),
                t("MisInformes.ImporteServido")
            ]
            //
            for (let i = 0; i < data.length; i++) {
                resultado.push({
                    Fecha: FechaDdMmAAAA(new Date(data[i].Fecha)),
                    Mercado: data[i].Mercado,
                    Proveedor: data[i].Proveedor,
                    Producto: data[i].NombreProducto + "(" + data[i].CodigoProducto + ")",
                    Lote: data[i].Lote,
                    NumeroPedidos: data[i].NumeroPedidos,
                    CantidadPedida: data[i].CantidadPedida,
                    CantidadEntregada: data[i].CantidadEntregada,
                    CantidadServida: data[i].CantidadServida,
                    Unidad: data[i].Unidad,
                    Precio: data[i].Precio,
                    Importe: data[i].Importe
                })
                resultadoExcel.push({})
                resultadoExcel[i][cabeceraExcel[0]] = FechaDdMmAAAA(new Date(data[i].Fecha))
                resultadoExcel[i][cabeceraExcel[1]] = data[i].Mercado
                resultadoExcel[i][cabeceraExcel[2]] = data[i].Proveedor
                resultadoExcel[i][cabeceraExcel[3]] = data[i].NombreProducto + "(" + data[i].CodigoProducto + ")"
                resultadoExcel[i][cabeceraExcel[4]] = data[i].Lote
                resultadoExcel[i][cabeceraExcel[5]] = data[i].NumeroPedidos
                resultadoExcel[i][cabeceraExcel[6]] = data[i].CantidadPedida
                resultadoExcel[i][cabeceraExcel[7]] = data[i].Unidad
                resultadoExcel[i][cabeceraExcel[8]] = data[i].CantidadEntregada
                resultadoExcel[i][cabeceraExcel[9]] = data[i].CantidadServida
                resultadoExcel[i][cabeceraExcel[10]] = data[i].Precio
                resultadoExcel[i][cabeceraExcel[11]] = data[i].Importe
            }
            estado.lineasPedidos = resultado
            estado.lineasExcel = resultadoExcel
            // Fuerza Renderizado
            setEstado({ ...estado, lineasPedidos: resultado, lineasExcel: resultadoExcel })
            actions.cambiarMensaje("")
        } else {
            actions.cambiarMensaje(t("MisInformes.NoHayLineasVenta"))
        }
    }



    const tituloYFiltros = (
        <Fragment>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 1 / 3 }}>
                    <Typography variant="verde30">
                        {t("MisInformes.ProductosProductores")}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 2 / 3, display: 'flex', /*justifyContent: 'center'*/ }}>
                    <Box>
                        <BotonFecha
                            //margenI={5}
                            nombre={t("MisInformes.Desde")}
                            fecha={FechaDdMmAAAA(estado.fechaInicio)}
                            actualizadorFecha={actualizadorFechaDesde}
                        />
                    </Box>
                    <Box>
                        <BotonFecha
                            margenI={5}
                            nombre={t("MisInformes.Hasta")}
                            fecha={FechaDdMmAAAA(estado.fechaFin)}
                            actualizadorFecha={actualizadorFechaHasta}
                        />
                    </Box>
                </Box>
                <Box sx={{ width: 1 / 3, display: 'flex', justifyContent: "flex-end" }}>
                    <Typography variant="verde15" sx={{ ml: 3 }}>
                        {t("MisInformes.Ver")}:
                    </Typography>
                    <Lista
                        id={"Inf"}
                        elementos={tipoInforme}
                        accion={handleFiltroInforme}
                        activos={estado.informe}
                    />
                </Box>
                <Box sx={{ ml: 3 }}>
                    <BotonXlsx data={estado.lineasExcel.filter(filtraProveedor)} filename={t("MisProductos.Productos")} />
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 3 / 3, display: 'flex', justifyContent: "flex" }}>
                    <Typography variant="verde15">
                        {t("MisInformes.Mercado")}:
                    </Typography>
                    {estado.mercado !== undefined &&
                        <Lista
                            id={"Mer"}
                            elementos={estado.seleccionMercados}
                            accion={handleFiltroMercado}
                            activos={estado.mercado}
                        />
                    }
                </Box>
                <Box sx={{ width: 1 / 3, display: 'flex', justifyContent: "flex-end" }}>
                    <Typography variant="verde15" sx={{ ml: 3 }}>
                        {t("MisInformes.Informe")}:
                    </Typography>
                    <Lista
                        id={"Art"}
                        elementos={agrupandoArticulos}
                        accion={handleFiltroAgrupandoArticulos}
                        activos={estado.agrupacion}
                    />
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 3 / 3, display: 'flex', justifyContent: "flex" }}>
                    <Typography variant="verde15">
                        {t("MisInformes.Proveedor")}:
                    </Typography>
                    {estado.proveedor !== undefined &&
                        <Lista
                            id={"Pro"}
                            elementos={estado.seleccionProveedores}
                            accion={handleFiltroProveedor}
                            activos={estado.proveedor}
                        />
                    }
                </Box>
            </Box>

        </Fragment>
    )


    function filtraProveedor(elemento) {
        let retorno = false
        if (estado.proveedor === 0) {
            // Retorma Siempre
            retorno = true
        } else {
            // Retorna si cumple
            if (elemento.Proveedor === estado.seleccionProveedores[estado.proveedor]) {
                retorno = true
            }
        }
        return retorno
    }


    const listaLineasListado = (
        estado.lineasPedidos.filter(filtraProveedor).length > 0 && (
            <Grid sx={{ mt: 0 }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="lineas de pedidos">
                        <TableHead>
                            <TableRow>
                                {estado.agrupacion === 0 ? <TableCell><Typography variant="azul15">{t("MisInformes.FechaEntrega")}</Typography></TableCell> : ""}
                                <TableCell><Typography variant="azul15">{t("MisInformes.Mercado")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisInformes.Proveedor")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisInformes.Producto")}</Typography></TableCell>
                                <TableCell><Typography variant="azul15">{t("MisInformes.Lote")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisInformes.Pedidos")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisInformes.CantidadPedida")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisInformes.CantidadPreparadaI")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisInformes.CantidadServida")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisInformes.Precio")}</Typography></TableCell>
                                <TableCell align="right"><Typography variant="azul15">{t("MisInformes.ImporteServido")}</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {estado.lineasPedidos.filter(filtraProveedor).map((l, i) => (
                                <TableRow
                                    key={"Prod" + l.ProCod + i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    {estado.agrupacion === 0 ? <TableCell>{l.Fecha}</TableCell> : ""}
                                    <TableCell>{l.Mercado}</TableCell>
                                    <TableCell>{l.Proveedor}</TableCell>
                                    <TableCell>{l.Producto}</TableCell>
                                    <TableCell>{l.Lote}</TableCell>
                                    <TableCell align="right">{l.NumeroPedidos}</TableCell>
                                    <TableCell align="right">{l.CantidadPedida + " " + l.Unidad}</TableCell>
                                    <TableCell align="right">{l.CantidadEntregada}</TableCell>
                                    <TableCell align="right">{l.CantidadServida}</TableCell>
                                    <TableCell align="right">{RedondeaPrecio(Number(l.Precio), 2)}{" €"}</TableCell>
                                    <TableCell align="right">{l.Importe !== 0 ? RedondeaPrecio(l.Importe, 2) + " €" : ""}</TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        )
    )



    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Container sx={{ mt: 1.5 }}>
                {tituloYFiltros}
                {estado.lineasAMostrar.length <= 0 || !estado.lineasAMostrar ? <MensajeCabecera /> : "" }
                {estado.lineasAMostrar && estado.lineasAMostrar.length > 0 && (
                    <Grid sx={{ mt: 0 }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="lineas de pedidos">
                                <TableHead>
                                    <TableRow>
                                        {/** Definir columnas dinámicamente según estado.agrupacion */}
                                        {(
                                            estado.agrupacion === 0
                                                ?
                                                [ // Desglosado
                                                    { id: "FechaPedido", label: t("MisInformes.FechaPedido") },
                                                    { id: "FechaEntrega", label: t("MisInformes.FechaEntrega") },
                                                    { id: "CodigoMercado", label: t("MisInformes.CodigoMercado") },
                                                    { id: "Mercado", label: t("MisInformes.Mercado") },
                                                    { id: "CodigoProveedor", label: t("MisInformes.CodigoProveedor") },
                                                    { id: "Proveedor", label: t("MisInformes.Proveedor") },
                                                    { id: "NumeroPedido", label: t("MisInformes.NumeroPedido") },
                                                    { id: "Lote", label: t("MisInformes.Lote") },
                                                    { id: "CodigoProducto", label: t("MisInformes.CodigoProducto") },
                                                    { id: "NombreProducto", label: t("MisInformes.NombreProducto") },
                                                    { id: "CantidadPedida", label: t("MisInformes.CantidadPedida"), numeric: true },
                                                    { id: "CantidadEntregada", label: t("MisInformes.CantidadEntregada"), numeric: true },
                                                    { id: "CantidadServida", label: t("MisInformes.CantidadServida"), numeric: true },
                                                    { id: "Diferencia", label: t("MisInformes.Diferencia"), numeric: true },
                                                    { id: "Unidad", label: t("MisInformes.Unidad") },
                                                    { id: "Precio", label: t("MisInformes.Precio"), numeric: true },
                                                    { id: "ImporteServido", label: t("MisInformes.ImporteServido"), numeric: true }
                                                ]
                                                :
                                                [ // Agrupado
                                                    { id: "Mercado", label: t("MisInformes.Mercado") },
                                                    { id: "CodigoProveedor", label: t("MisInformes.CodigoProveedor") },
                                                    { id: "Proveedor", label: t("MisInformes.Proveedor") },
                                                    { id: "NumeroPedidos", label: t("MisInformes.NumeroPedidos"), numeric: true },
                                                    { id: "CantidadPedida", label: t("MisInformes.CantidadPedida"), numeric: true },
                                                    { id: "CantidadEntregada", label: t("MisInformes.CantidadEntregada"), numeric: true },
                                                    { id: "CantidadServida", label: t("MisInformes.CantidadServida"), numeric: true },
                                                    { id: "Diferencia", label: t("MisInformes.Diferencia"), numeric: true },
                                                    { id: "MediaPrecio", label: t("MisInformes.MediaPrecio"), numeric: true },
                                                    { id: "Importe", label: t("MisInformes.ImporteServido"), numeric: true }
                                                ].filter(Boolean) // Filtra columnas nulas
                                        ).map((column) => (
                                            <TableCell key={column.id} align={column.numeric ? "right" : "left"}>
                                                <TableSortLabel
                                                    active={orderBy === column.id}
                                                    direction={orderBy === column.id ? order : "asc"}
                                                    onClick={() => handleRequestSort(column.id)}
                                                >
                                                    <Typography variant="azul15">{column.label}</Typography>
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {sortedData.map((l, i) => (
                                        <TableRow key={`Prod${l.CodigoProducto}${i}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            {/** Se renderizan las celdas según las columnas dinámicas */}
                                            {(
                                                estado.agrupacion === 1
                                                    ? [
                                                        l.Mercado,
                                                        l.CodigoProveedor,
                                                        l.Proveedor,
                                                        l.NumeroPedidos,
                                                        l.CantidadPedida ,
                                                        l.CantidadEntregada,
                                                        l.CantidadServida,
                                                        l.Diferencia,
                                                        l.MediaPrecio + " €",
                                                        l.Importe
                                                    ]
                                                    : [
                                                        l.FechaPedido,
                                                        l.FechaEntrega,
                                                        l.CodigoMercado,
                                                        l.Mercado,
                                                        l.CodigoProveedor,
                                                        l.Proveedor,
                                                        l.NumeroPedido,
                                                        l.Lote,
                                                        l.CodigoProducto,
                                                        l.NombreProducto,
                                                        l.CantidadPedida,
                                                        l.CantidadEntregada,
                                                        l.CantidadServida,
                                                        l.Diferencia,
                                                        l.Unidad,
                                                        l.Precio + " €",
                                                        l.ImporteServido + " €",
                                                    ]
                                            ).filter(value => value !== null && value !== undefined).map((value, index) => (
                                                <TableCell key={index} align={typeof value === "number" ? "right" : "left"}>
                                                    {value}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                )}

                <br />
                <br />
            </Container>
        </ThemeProvider>
    );
}

export default withTranslation()(InfProductosProductores)