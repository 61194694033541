import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
//import CambiaImagen from "../../components/CambiaImagen.js";
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
//import Checkbox from '@mui/material/Checkbox';
import CajaTexto from './../../components/CajaTexto.js';
//import Select from "@mui/material/Select";
//import FormControl from "@mui/material/FormControl";
import ThemeGeroa from './../../ThemeGeroa.js'
import { withTranslation } from 'react-i18next'
//import { alpha, styled } from '@mui/material/styles';
//import InputBase from '@mui/material/InputBase';
//import Box from '@mui/material/Box';
//import MenuItem from '@mui/material/MenuItem';
import { BuscaIndice, PrecioLineaConIva, RedondeaPrecio } from '../../config/funcionesPropias.js'
import { listaProductosProveedor } from './../../apis/api-producto.js'
import { updateLineasPedidos } from '../../apis/api-pedido.js';
import { getProductosMercado } from '../../apis/api-mercado.js';
import { useStore } from 'react-create-use-store'
import store from './../../adderStore.js'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

import DialogTitle from '@mui/material/DialogTitle'


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

//import config from './../../config/config.js'




const DialogAñadirProducto = ({ t, ...props }) => {

    //actualizador={actualizadorProductos}
    //p={crearProducto.pedido}

    const restaExistencias = props.p.PedREx // 1: Resta. 0: No resta.
    const productosPedido = Object.entries({ ...props.p.productos }).map(i => i[1])
    const pedido = "" + props.p.PedNum
    const entregado = productosPedido[0].LinEnt !== ''
    const servido = productosPedido[0].LinSer !== ''
    const codigoMercado = props.m

    const { state, /*actions*/ } = useStore(store);
    const [open, setOpen] = useState(true)
    const handleCloseAbandonarDialogProducto = () => {
        // Dejar la ficha como entró. //
        //setFicha(prod)
        //ficha.modificado = false
        setOpen(false)
        props.actualizador(null, false)
    }



    const handleCloseGuardarDialogProducto = async () => {
        //let resultado = estado.productos.filter((p) => p.ProAna)
        let resultado = estado.productos.filter((p) => p.LinCan > 0)
        if (resultado.length > 0) {
            resultado.forEach(p => {
                if (entregado) {
                    p.LinEnt = p.LinCan
                }
                if (servido) {
                    p.LinSer = p.LinCan
                }
                //console.log("Resta: ", restaExistencias)
                // Si hay que restar. //
                if (p.ProEIn > 0 && (restaExistencias === 1 || entregado)) {
                    if (entregado) {
                        p.Exis = - p.LinEnt
                    } else {
                        p.Exis = - p.LinCan
                    }
                } else {
                    p.Exis = 0
                }
            })

            updateLineasPedidos(resultado).then((data) => {
                //console.log('todos los Productos = ', data)
                if (data.error) {
                    console.log(data.error)
                }
                else {
                    // Cerrar el Dialog
                    setOpen(false)
                    props.actualizador(null, false)
                }
            })
        }
    }


    const [estado, setEstado] = useState(
        {
            carga: true,
            productos: []
        }
    )

    useEffect(() => {
        if (estado.carga) {
            loadProductos()
            estado.carga = false
        }
    })


    const handleChangeProducto = (campo, i) => event => {
        let est = estado
        //
        /*if (campo === "Check") {
            est.productos[i].ProAna = event.target.checked
        }
        else*/
        if (campo === "Cantidad") {
            est.productos[i].LinCan = event
        }
        //
        setEstado({ ...est })
    }



    async function loadProductos() {
        let datos = []
        // Comprobar si el usuario es coordinador. En caso de que no lo sea (es productor), listar solo los pruductos del productor.
        // En caso de que sea coordinador, buscar todos los pedidos de ese mercado.
        if (state.esProductor) {
            // Busca los productos en funcion del filtro:
            await listaProductosProveedor({ proveedor: state.codProveedor, filtro: 1, desde: 0, cuantos: 10000, idioma: state.idioma }, { texto: "" }).then((data) => {
                if (data.error) {
                    console.log(data.error)
                }
                else {
                    // Filtramos los productos del mercado
                    let datosFiltrados = data.filter((p) => {
                        let ind = BuscaIndice(p.Mercados, "MerCod", codigoMercado)
                        if (ind === -1) return false
                        if (p.Mercados[ind].TarVis !== 1) return false
                        return true
                    })
                    //
                    datosFiltrados.forEach((paa) => {
                        if (BuscaIndice(productosPedido, "LinArt", paa.ProCod) === -1) {
                            let tarifa = paa.Mercados.filter(m => m.MerCod === codigoMercado)
                            if (tarifa.length > 0) {
                                let precio = tarifa[0].TarVal
                                datos.push({
                                    LinNum: pedido,
                                    LinArt: paa.ProCod,
                                    LinLot: paa.ProLot,
                                    //LinDes: paa.ProDesC,
                                    LinDes: state.idioma === "Es" ? paa.ProDesC : paa.ProDesE,
                                    LinPro: state.codProveedor,
                                    LinCan: "",
                                    LinEnt: "",
                                    LinSer: "",
                                    LinPre: precio,
                                    LinIva: paa.ProIva,
                                    //LinEst: "S",
                                    LinInc: "",
                                    ProExi: Number(paa.ProExi),
                                    ProEIn: Number(paa.ProEIn),
                                    ProAna: false
                                })
                            }
                        }
                    })
                    console.log("Datos que devuelve la api(lista de productos): " + JSON.stringify(datos))
                    estado.productos = datos
                    // Forma el Array eliminando los productos que ya existen y añadiendo los campos que necesitamos
                    // Fuerza Renderizado
                    setEstado({ ...estado })
                    //console.log('Estado = ', estado)
                }
            })
        }
        else {
            // Hacer aqui la llamada a los productos del mercado
            try {
                console.log("Mercado seleccionado: ", codigoMercado)
                // 🔍 Se llama a la API para obtener los productos del mercado seleccionado
                const productos = await getProductosMercado(codigoMercado);
                console.log("Productos cargados:", productos);

                productos.forEach((producto) => {
                    if (Number(producto.precioProducto) > 0) {
                        datos.push({
                            LinNum: pedido,
                            LinArt: producto.codigoProducto,
                            LinLot: producto.loteProducto,
                            //LinDes: paa.ProDesC,
                            LinDes: state.idioma === "Es" ? producto.descripcionCastellano : producto.descripcionEuskera,
                            LinPro: state.codProveedor,
                            LinCan: "",
                            LinEnt: "",
                            LinSer: "",
                            LinPre: producto.precioProducto,
                            LinIva: producto.codigoIVA,
                            //LinEst: "S",
                            LinInc: "",
                            ProExi: Number(producto.existenciasDisponibles),
                            ProEIn: Number(producto.existenciasIniciales),
                            ProAna: false
                        })
                    }
                })
                // 🧩 Se actualiza el estado con los productos obtenidos
                console.log("Datos que devuelve la api(lista de productos): " + JSON.stringify(datos))
                estado.productos = datos
                // Forma el Array eliminando los productos que ya existen y añadiendo los campos que necesitamos
                // Fuerza Renderizado
                setEstado({ ...estado })
            } catch (error) {
                // ⚠️ Manejo de errores: si falla la llamada a la API, se registra en la consola
                console.error("Error al cargar los productos:", error);
            }
        }


    }

    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Dialog
                open={open}
                maxWidth="md"
            >
                <DialogTitle>
                    <Typography variant="azul15">{t("MisProductos.Añadir")}</Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    {/* 
                                    <TableCell></TableCell>
                                    */}
                                    <TableCell sx={{ width: 80 }}>{t("MisPedidos.Cantidad")}</TableCell>
                                    <TableCell>{t("MisPedidos.Producto")}</TableCell>
                                    <TableCell sx={{ width: 100 }} align="right">{t("MisPedidos.Precio")}</TableCell>
                                    <TableCell align="right">{t("MisPedidos.Existencias")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {estado.productos.map((p, i) => (
                                    <TableRow
                                        key={"Prod" + p.ProCod ? p.ProCod : p.codigoProducto + i}
                                        sx={{
                                            '&:last-child td, &:last-child th': {
                                                border: 0,
                                                paddingTop: 1, paddingBottom: 1
                                            }
                                        }}
                                    >
                                        {/* 
                                        <TableCell>
                                            <Checkbox
                                                id={"ProAna" + p.LinArt + i}
                                                color="primary"
                                                checked={p.Ana}
                                                onChange={handleChangeProducto("Check", i)}
                                            />
                                        </TableCell>
                                        */}
                                        <TableCell sx={{ paddingTop: 0.2, paddingBottom: 0.2 }}>
                                            <FormControlLabel
                                                sx={{ width: 40 }}
                                                aria-label="Acknowledge"
                                                onClick={event => event.stopPropagation()}
                                                onFocus={event => event.stopPropagation()}
                                                control={<CajaTexto
                                                    id={"ProCan" + p.LinArt + p.LinCan + i}
                                                    tipo={"number"}
                                                    ancho={60}
                                                    valor={p.LinCan}
                                                    accion={handleChangeProducto("Cantidad", i)}
                                                />}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ paddingTop: 0.2, paddingBottom: 0.2 }} component="th" scope="row">
                                            {p.LinDes}{" (" + p.LinArt + ")"}
                                        </TableCell>
                                        <TableCell sx={{ paddingTop: 0.2, paddingBottom: 0.2 }} align="right">{RedondeaPrecio(p.LinPre)} {" (" + PrecioLineaConIva(p.LinPre, state.gruposIva, p.LinIva, 1) + ")"}</TableCell>
                                        <TableCell sx={{ paddingTop: 0.2, paddingBottom: 0.2 }} align="right">{p.ProExi}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        sx={{ mb: 1, mr: 2, width: 110, height: 30 }}
                        onClick={handleCloseAbandonarDialogProducto}
                    >
                        {t("MisProductos.Abandonar")}
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ mb: 1, mr: 2, width: 90, height: 30 }}
                        onClick={handleCloseGuardarDialogProducto}
                    >
                        {t("MisProductos.Guardar")}
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}
export default withTranslation()(DialogAñadirProducto)

