import config from './../config/config.js'

const rutaApi = config.rutaApis + '/entregas'





/////////////////////////////////////////////////////////
// Informe Lineas de Pedidos por Estado
const listaEntregasProvCoor = (params) => {
  console.log("Ruta de llamada: ", rutaApi + '/listaEntregasProvCoor/' + params.proveedor + '/' + params.esProductor + '/' + params.mercado + '/' + params.diaDesde + '/' + params.diaHasta + '/' + params.idioma)
   return fetch(rutaApi + '/listaEntregasProvCoor/' + params.proveedor + '/' + params.esProductor + '/' + params.mercado + '/' + params.diaDesde + '/' + params.diaHasta + '/' + params.idioma , {
   method: 'GET',
 }).then((response) => {
   return response.json()
 }).catch((err) => console.log(err))
}


const updateEntregas = (params) => {
  return fetch(rutaApi + '/updateEntregas', {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}




export {
  listaEntregasProvCoor,
  updateEntregas
}