import config from './../config/config.js'

const rutaApi = config.rutaApis + '/productos'


const countProductosProveedor = (params, texto) => {
  if (texto === undefined) {
    texto = ""
  }
  return fetch(rutaApi + '/countProductosProveedor/' + params.proveedor + '/' + params.filtro, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(texto)
  }).then(response => {
    return response.json()
  }).catch((err) => console.log(err))

}


const listaProductosProveedor = (params, texto) => {
  //console.log(rutaApi + '/listaProductosProveedor/' + params.proveedor + '/' + params.filtro + '/' + params.desde + '/' + params.cuantos + '/' + params.idioma)
  return fetch(rutaApi + '/listaProductosProveedor/' + params.proveedor + '/' + params.filtro + '/' + params.desde + '/' + params.cuantos + '/' + params.idioma, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(texto)
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}



const listaProductosMercadoProveedor = (params, texto) => {
  return fetch(rutaApi + '/listaProductosMercadoProveedor/' + params.esProductor + '/' + params.proveedor + '/' + params.filtro + '/' + params.mercado + '/' + params.idioma, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(texto)
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}




const crearProductoTarifas = (params) => {
  return fetch(rutaApi + '/crearProductoTarifas', {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}


const updateProductoTarifas = (params) => {
  return fetch(rutaApi + '/updateProductoTarifas', {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ producto: params.producto, imagen: params.imagen })
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
  })
}


const updateFotoProducto = (formData) => {
  return fetch(rutaApi + '/updateFotoProducto', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ image: formData })
    //headers: {
    //  'Content-Type': 'multipart/form-data; ',
    //},
  }).then((response) => {
    return response.json()
  }).catch((err) => {
    console.log(err)
    alert("Ocurrió un Error al cargar la foto en el servidor");
    return err
  })
}


export {
  countProductosProveedor,
  listaProductosProveedor,
  listaProductosMercadoProveedor,
  crearProductoTarifas,
  updateProductoTarifas,
  updateFotoProducto
}