import React, { Fragment, useState, useEffect } from "react";
import { useStore } from 'react-create-use-store';
import { withTranslation } from 'react-i18next'
import { ToastContainer, /* toast */ } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PedidoLazy from "./PedidoLazy.js"

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SaveIcon from '@mui/icons-material/Save';
//import EmailIcon from '@mui/icons-material/Email';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Divider from "@mui/material/Divider";
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Pagination from "@mui/material/Pagination";
import Typography from '@mui/material/Typography';

import { ThemeProvider } from '@mui/material/styles';

import store from './../../adderStore.js'
import ThemeGeroa from './../../ThemeGeroa.js'
//import auth from './../../apis/auth-helper.js'
import { listaLinPedDiaProvCoor, updateLineasPedidos } from './../../apis/api-pedido.js'
import { updateEntregas } from './../../apis/api-entrega.js'

import CajaTexto from './../../components/CajaTexto.js';
import DialogoError from "./../../components/DialogoError.js";

import {
    FechaAaaaMmDd,
    FechaLargaEsp,
    PrecioConIva,
    PrecioLineaConIva,
    PrecioLineaConIvaNumber,
    BuscaIndice,
    /* OrdenaArray2Campos,*/
    OrdenaArray4Campos,
    RedondeaPrecio
} from "./../../config/funcionesPropias.js";

//import 'react-calendar/dist/Calendar.css'
import './MiCalendario.css'
import MiCalendario from "./MiCalendario.js";

import DialogAñadirPedido from './DialogAñadirPedido.js'
import DialogAñadirProducto from './DialogAñadirProducto.js'
import DialogCambiarFecha from './DialogCambiarFecha.js'
import DialogSalida from "./DialogSalida.js";

//import { enviaEmailAlbaran } from "../../apis/api-email.js";
//import EmailAlbaran from './EmailAlbaran.js'
import { generaPdfsAlbaranes } from '../../apis/api-fichero.js'
//import { render } from '@react-email/render';
import MensajeCabecera from "../../components/MensajeCabecera.js";

const MisPedidos = ({ t, ...props }) => {

    //const jwt = auth.isAuthenticated()
    //console.log('autenticado = ', jwt)

    const { state, /*actions*/ } = useStore(store);

    /////////////////////////////////////////////////////////////////////
    // Pantallas que se Visualizan tras la seleccion en el calendario  //
    let cuantasLineas = 500

    const [estado, setEstado] = useState(
        {
            carga: false,
            verCalendario: true,
            verDialogoSalida: false,
            dia: FechaAaaaMmDd(new Date()),
            pagina: 0,
            numPaginas: 0,
            filtro: 0,
            todasLineasPedidas: [],
            lineasPedidos: [],
            modificaciones: false,
            verError: false,
            textoError: ""
        }
    )


    function formaDePago(f) {
        let ret
        if (f === "R") {
            ret = t("MisPedidos.Recibo")
        } else if (f === 'T') {
            ret = t("MisPedidos.Transferencia")
        } else if (f === 'J') {
            ret = t("MisPedidos.Tarjeta")
        } else if (f === 'V') {
            ret = t("MisPedidos.TPV")
        } else if (f === 'P') {
            ret = t("MisPedidos.Productor")
        }
        return ret
    }

    /////////////////////////////////////////////////////////////////////
    // Manejo del Dialog que se monta para Añadir Productos a un Pedido
    const [crearProducto, setCrearProducto] = useState({
        crear: false,
        mercado: '',
        pedido: ''
    })
    const handleCrearProducto = (pedido, merCod) => event => {
        let cp = crearProducto
        cp.crear = true
        cp.mercado = merCod
        cp.pedido = pedido
        setCrearProducto({ ...cp })
        //console.log('Crear producto = ', crearProducto)
        console.log("Datos de pedido: pedido: ", crearProducto.pedido, "     Mercado: ", crearProducto.mercado)
    }

    function actualizadorProductos(p, g, r) {
        let cp = crearProducto
        cp.crear = false
        cp.pedido = ''
        setCrearProducto({ ...cp })
        //
        estado.carga = true
        setEstado({ ...estado })
    }



    //////////////////////////////////////////////////////////////////////
    // Manejo del Dialog que se monta para Añadir Pedidos a un Mercado. //
    //////////////////////////////////////////////////////////////////////
    const [crearPedido, setCrearPedido] = useState({
        crear: false,
        mercado: ''
    })
    const handleCrearPedido = (merCod) => event => {
        let cp = crearPedido
        cp.crear = true
        cp.mercado = merCod
        setCrearPedido({ ...cp })
    }

    function actualizaCrearPedido(creado) {
        setCrearPedido({ ...crearPedido, crear: false })
        // Si ha creado un pedido. //
        if (creado) {
            //estado.carga = true
            setEstado({ ...estado, carga: true })
        }
    }


    /////////////////////////////////////////////////////////////////////////////////
    // Manejo del Dialog que se monta para Cambiar la Fecha de Entrega a un Pedido //
    /////////////////////////////////////////////////////////////////////////////////
    const [cambiarFecha, setCambiarFecha] = useState({
        cambiar: false,
        pedido: ''
    })
    const handleCambiarFecha = (pedido) => event => {
        let cf = cambiarFecha
        cf.cambiar = true
        cf.pedido = pedido
        setCambiarFecha({ ...cf })
    }

    function actualizaCambiarFecha(mod) {
        setCambiarFecha({ ...cambiarFecha, cambiar: false, pedido: '' })
        // Si se ha modificado la Fecha. //
        if (mod) {
            estado.carga = true
            setEstado({ ...estado })
        }
    }


    const handlePagina = (event, value) => {
        estado.pagina = value
        // Cargar
        visualizaLineasPedidos(estado.pagina)
    }


    /////const [expanded, setExpanded] = React.useState(false);
    const handleChangeExpandido = (iM, iP) => (event) => {
        let est = estado
        est.lineasPedidos[iM].pedidos[iP].expandido = !est.lineasPedidos[iM].pedidos[iP].expandido
        setEstado({ ...est })
    };


    useEffect(() => {
        if (estado.carga) {
            loadLineasPedidosInicialesDia()
            // Actualiza el estado sin Repintar. //
            estado.carga = false
        }
    }, [estado.carga])



    // Contar los productos para establecer el calendario
    async function loadLineasPedidosInicialesDia() {
        // Cuenta los pedidos para organizar la paginacion
        await listaLinPedDiaProvCoor({ proveedor: state.codProveedor, esProductor: state.esProductor ? "1" : "0", dia: estado.dia, idioma: state.idioma }).then((data) => {
            if (data.error) {
                estado.verError = true
                estado.textoError = "Error leyendo datos"
                setEstado({ ...estado })
                console.log("error: ", data)
            }
            else {
                //console.log("Data Inicial: ", data)
                estado.todasLineasPedidas = data
                // Poner Marca en Todas las Lineas de modificado a Falso
                // Poner Existencias a Descontar a cero
                estado.todasLineasPedidas.forEach(linea => {
                    linea.modificado = false
                    linea.Exis = 0
                })

                let lineasPedidosTotales = data.length
                // Si hemos cambiado la fecha al pedido y no hay líneas. //
                if (lineasPedidosTotales === 0) {
                    // vaciar los pedidos actuales. //
                    estado.lineasPedidos = []
                    //setEstado({ ...estado, verCalendario: true })
                } else {
                    // Ponemos el Nº lineas por página
                    let num = Math.round(lineasPedidosTotales / cuantasLineas)
                    if (lineasPedidosTotales / cuantasLineas > num) {
                        num++
                    }
                    if (num < 1) { num = 1 }
                    // Paginas
                    estado.numPaginas = num
                    estado.pagina = 1
                    //
                    // Carga las lineas de Pedidos
                    visualizaLineasPedidos(estado.pagina)
                }
            }
        })
    }



    function listaRangoLin(d, c) {
        let tmpTodas = estado.todasLineasPedidas
        let devolver = tmpTodas.slice(d, d + c)
        return devolver
    }




    // Carga de datos Inicial --> despues de la carga renderiza
    function visualizaLineasPedidos(pagina) {
        console.log("Todas las lineas pedidas: ", estado.todasLineasPedidas)
        // Calcula el registro del producto Inicial
        // ¡¡ COMIENZA EN EL 0 !!
        let desde = (cuantasLineas * (pagina - 1))
        // Busca los productos en funcion del filtro:
        let data = listaRangoLin(desde, cuantasLineas)
        //console.log('datos = ', data)
        if (data.length > 0) {
            // Reorganiza las lineas
            let tmpCodMercado = "", tmpCodPedido = ""
            let resultado = []
            let contMercados = 0
            let contPedidos = desde
            //
            for (let i = 0; i < data.length; i++) {
                if (data[i].MerCod !== tmpCodMercado) {
                    tmpCodMercado = data[i].MerCod
                    resultado.push({
                        MerCod: data[i].MerCod,
                        MerDes: state.idioma === "Es" ? data[i].MerDesC : data[i].MerDesE,
                        MerEnt: data[i].LinEnt !== "",
                        MerSer: data[i].LinSer !== "",
                        // Mail ya Enviado (No existe el campo PedEnv)
                        MerEnv: false, //data[i].PedEnv === 1,
                        MerMon: data[i].MerMon,
                        pedidos: []
                    })
                    contMercados++
                    contPedidos = 0
                }
                if (data[i].MerCod + data[i].PedNum !== tmpCodMercado + tmpCodPedido) {
                    tmpCodPedido = data[i].PedNum
                    resultado[contMercados - 1].pedidos.push({
                        PedNum: data[i].PedNum,
                        PedEst: data[i].PedEst,
                        PedFec: data[i].PedFec,
                        PedCli: data[i].PedCli,
                        PedRaz: data[i].PedRaz,
                        PedCal: data[i].PedCal,
                        PedPob: data[i].PedPob,
                        PedCpo: data[i].PedCpo,
                        PedPro: data[i].PedPro,
                        PedTel: data[i].PedTel,
                        PedEma: data[i].PedEma,
                        //PedEnv: data[i].PedEnv,
                        expandido: false,
                        productos: [],
                        // Necesario para cambiar Fecha-Duplicar pedido. //
                        PedFEn: data[i].PedFEn, // Fecha Entrega
                        PedMer: data[i].PedMer, // Mercado
                        PedFPa: data[i].PedFPa, // Forma Pago
                        PedREx: data[i].PedREx, // Resta Existencias
                        PedTEn: data[i].PedTEn, // Tipo Entrega
                        // Actualizar Gastos Entrega. //
                        PedGas: data[i].PedGas
                    })
                    contPedidos++
                }
                // Si o Si añade producto
                resultado[contMercados - 1].pedidos[contPedidos - 1].productos.push({
                    ProContadorGeneral: (pagina - 1) * cuantasLineas + i,
                    LinFac: data[i].LinFac,
                    LinNum: data[i].LinNum,
                    LinArt: data[i].LinArt,
                    ProDes: state.idioma === "Es" ? data[i].ProDesC : data[i].ProDesE,
                    LinPre: data[i].LinPre,
                    ProIva: data[i].ProIva,
                    ProUni: data[i].ProUni,
                    LinCan: data[i].LinCan,
                    LinEnt: data[i].LinEnt,
                    LinSer: data[i].LinSer,
                    LinLot: data[i].LinLot,
                    LinInc: data[i].LinInc,
                    // Necesario para Duplicar Pedido. //
                    LinPro: data[i].LinPro,
                    LinDes: data[i].LinDes,
                    ProExi: Number(data[i].ProExi),
                    ProFEi: data[i].ProFEi,
                    ProEIn: Number(data[i].ProEIn),
                    // Necesario para visaualiza el precio en rojo si ha cambiado
                    LinTVa: data[i].TarVal

                })
                //console.log('al leer = ', resultado)
            }
            estado.lineasPedidos = resultado
            console.log("Lineas de pedidos: ", estado.lineasPedidos)
            // Fuerza Renderizado
            setEstado({ ...estado })
        }
    }



    const handleVolverAlCalendario = event => {
        //if(!estado.modificaciones){
        //setEstado({ ...estado, verCalendario: true })
        //}
        if (estado.modificaciones) {
            // Ver el dialogo de salir si o ni
            setEstado({ ...estado, verDialogoSalida: true })
        } else {
            // vaciar los pedidos actuales. //
            estado.lineasPedidos = []
            setEstado({ ...estado, verCalendario: true })
        }
    }



    const handleCerrarError = () => {
        estado.verError = false
        estado.textoError = ""
        setEstado({ ...estado })
    }



    function actualizadorSalida(guardar) {
        estado.verDialogoSalida = false
        if (guardar) {
            // Registrar los cambios antes de salir. //
            handleGuardar().then(() => {
                if (!estado.modificaciones) {
                    // vaciar los pedidos actuales. //
                    estado.lineasPedidos = []
                    setEstado({ ...estado, verCalendario: true })
                }
            })
        } else {
            // vaciar los pedidos actuales. //
            estado.lineasPedidos = []
            setEstado({ ...estado, modificaciones: false, verCalendario: true })
        }
    }

    const comprobarLineasNoEntregadas = (mercado) => {
        console.log("Mercado a comprobar: ", mercado)
        let hayPendientes = false; // Inicializamos correctamente
    
        for (const lin of estado.todasLineasPedidas) {
            if (lin.MerCod === mercado && lin.LinEnt === "") {
                hayPendientes = true;
                break; // Salimos del bucle en cuanto encontramos una pendiente
            }
        }
    
        console.log(hayPendientes ? "Existen pedidos sin entregar" : "NO existen pedidos sin entregar");
        return hayPendientes;
    };

    const comprobarLineasNoServidas = (mercado) => {
        console.log("Mercado a comprobar: ", mercado)
        let hayPendientes = false; // Inicializamos correctamente
    
        for (const lin of estado.todasLineasPedidas) {
            if (lin.MerCod === mercado && lin.LinSer === "") {
                hayPendientes = true;
                break; // Salimos del bucle en cuanto encontramos una pendiente
            }
        }
    
        console.log(hayPendientes ? "Existen pedidos sin entregar" : "NO existen pedidos sin entregar");
        return hayPendientes;
    };




    const handleGuardar = async (event) => {
        let arrayARegistrar = []
        //
        let tpBusqueda
        let arrayAEnviarMails = []
        // cambiar map() por forEach() //
        estado.todasLineasPedidas.forEach((lin) => {
            if (lin.modificado) {
                arrayARegistrar.push({
                    LinNum: lin.LinNum,
                    LinArt: lin.LinArt,
                    LinLot: lin.LinLot,
                    LinDes: lin.LinDes,
                    LinPro: state.codProveedor,
                    LinCan: lin.LinCan,
                    LinEnt: lin.LinEnt,
                    LinSer: lin.LinSer,
                    LinInc: lin.LinInc.trim(),
                    Exis: lin.Exis
                })
                //
                tpBusqueda = BuscaIndice(arrayAEnviarMails, "MerCod", lin.MerCod)
                if (tpBusqueda === -1 && lin.LinSer !== "") {
                    arrayAEnviarMails.push({ MerCod: lin.MerCod })
                }
            }
        })
        //
        tpBusqueda = estado.todasLineasPedidas.filter((lin) => {
            return BuscaIndice(arrayAEnviarMails, "MerCod", lin.MerCod) > -1
        })
        arrayAEnviarMails = OrdenaArray4Campos(tpBusqueda, "MerCod", "PedCli", "PedNum", "ProPro")
        //
        //
        // Trocea el array de otra manera (agrupando por mercado --> cliente -- > pedido --> lineas del pedido)
        let mercados = []
        let contMercados = 0
        let contClientes = 0
        let contPedidos = 0
        let contProductores = 0
        let mercado = ""
        let cliente = ""
        let pedido = ""
        let productor = ""
        let linea = ""
        let tpDir1, tpDir2
        let tpFpg
        for (let i = 0; i < arrayAEnviarMails.length; i++) {
            if (mercado !== arrayAEnviarMails[i].MerCod) {
                mercado = arrayAEnviarMails[i].MerCod
                cliente = ''
                mercados.push({
                    fichero: state.codProveedor + "_" + mercado + "_" + arrayAEnviarMails[i].PedFEn.substr(0, 4) + arrayAEnviarMails[i].PedFEn.substr(5, 2) + arrayAEnviarMails[i].PedFEn.substr(8, 2) + '.pdf',
                    clientes: []
                })
                contMercados++
                contClientes = 0
                contPedidos = 0
                contProductores = 0
            }
            if (mercado + cliente !== arrayAEnviarMails[i].MerCod + arrayAEnviarMails[i].PedCli) {
                mercado = arrayAEnviarMails[i].MerCod
                cliente = arrayAEnviarMails[i].PedCli
                pedido = ''
                tpDir1 = { direccion: arrayAEnviarMails[i].PedCal, codigoPostal: arrayAEnviarMails[i].PedCpo, poblacion: arrayAEnviarMails[i].PedPob, telefono: arrayAEnviarMails[i].PedTel }
                tpDir2 = { nombre: arrayAEnviarMails[i].PedCal, detalle: arrayAEnviarMails[i].PedCpo, localidad: arrayAEnviarMails[i].PedPob, telefono: arrayAEnviarMails[i].PedTel }
                mercados[contMercados - 1].clientes.push({
                    Cliente: cliente,
                    dir: arrayAEnviarMails[i].PedTEn !== 'P' ? tpDir1 : {},
                    puntoEntrega: arrayAEnviarMails[i].PedTEn === 'P' ? tpDir2 : {},
                    iva: arrayAEnviarMails[i].CliIva === 0 ? false : true,
                    fechaEntrega: arrayAEnviarMails[i].PedFEn.substr(0, 10),
                    mercado: state.idioma === "Es" ? arrayAEnviarMails[i].MerDesC : arrayAEnviarMails[i].MerDesE,
                    name: arrayAEnviarMails[i].PedRaz,
                    //
                    pedidos: []
                })
                contClientes++
                contPedidos = 0
                contProductores = 0
            }
            if (mercado + cliente + pedido !== arrayAEnviarMails[i].MerCod + arrayAEnviarMails[i].PedCli + arrayAEnviarMails[i].PedNum) {
                mercado = arrayAEnviarMails[i].MerCod
                cliente = arrayAEnviarMails[i].PedCli
                pedido = arrayAEnviarMails[i].PedNum
                productor = ''
                if (arrayAEnviarMails[i].PedFPa === 'R') {
                    tpFpg = t("MisPedidos.Recibo")
                } else if (arrayAEnviarMails[i].PedFPa === 'J') {
                    tpFpg = t("MisPedidos.Tarjeta")
                } else if (arrayAEnviarMails[i].PedFPa === 'T') {
                    tpFpg = t("MisPedidos.Transferencia")
                } else if (arrayAEnviarMails[i].PedFPa === 'V') {
                    tpFpg = t("MisPedidos.TPV")
                } else if (arrayAEnviarMails[i].PedFPa === 'P') {
                    tpFpg = t("MisPedidos.Productor")
                }
                mercados[contMercados - 1].clientes[contClientes - 1].pedidos.push({
                    pedido: pedido,
                    fPago: tpFpg,
                    total: 0,
                    //
                    productores: []
                })
                contPedidos++
                contProductores = 0
            }
            if (mercado + cliente + pedido + productor !== arrayAEnviarMails[i].MerCod + arrayAEnviarMails[i].PedCli + arrayAEnviarMails[i].PedNum + arrayAEnviarMails[i].ProPro) {
                mercado = arrayAEnviarMails[i].MerCod
                cliente = arrayAEnviarMails[i].PedCli
                pedido = arrayAEnviarMails[i].PedNum
                productor = arrayAEnviarMails[i].ProPro
                linea = ''
                mercados[contMercados - 1].clientes[contClientes - 1].pedidos[contPedidos - 1].productores.push({
                    codProductor: productor,
                    productor: arrayAEnviarMails[i].UsurazonSocial,
                    //
                    lineas: []
                })
                contProductores++
            }
            if (mercado + cliente + pedido + productor + linea !== arrayAEnviarMails[i].MerCod + arrayAEnviarMails[i].PedCli + arrayAEnviarMails[i].PedNum + arrayAEnviarMails[i].ProPro + arrayAEnviarMails[i].LinArt) {
                mercado = arrayAEnviarMails[i].MerCod
                cliente = arrayAEnviarMails[i].PedCli
                pedido = arrayAEnviarMails[i].PedNum
                productor = arrayAEnviarMails[i].ProPro
                linea = arrayAEnviarMails[i].LinArt
                mercados[contMercados - 1].clientes[contClientes - 1].pedidos[contPedidos - 1].productores[contProductores - 1].lineas.push({
                    description2: arrayAEnviarMails[i].LinDes,
                    cantidadServida: arrayAEnviarMails[i].LinSer,
                    precio: PrecioConIva(arrayAEnviarMails[i].LinPre, state.gruposIva, arrayAEnviarMails[i].ProIva), //+ " € / " + state.uniAVer[arrayAEnviarMails[i].ProUni],
                    importe: PrecioLineaConIva(arrayAEnviarMails[i].LinPre, state.gruposIva, arrayAEnviarMails[i].ProIva, arrayAEnviarMails[i].LinSer)
                })
                //
                mercados[contMercados - 1].clientes[contClientes - 1].pedidos[contPedidos - 1].total += PrecioLineaConIvaNumber(arrayAEnviarMails[i].LinPre, state.gruposIva, arrayAEnviarMails[i].ProIva, arrayAEnviarMails[i].LinSer)
            }
        }
        // Arregla los totales de los pedidos
        mercados.forEach(mercado => {
            mercado.clientes.forEach(cliente => {
                cliente.pedidos.forEach(pedido => {
                    pedido.total = RedondeaPrecio(pedido.total, 2)
                })
            })
        })
        if (arrayARegistrar.length > 0) {
            /*
            if (mercados.length > 0) {
                toast.info('Email Albaran a: ' + clientes[0].CliEma, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: props => finEnviosMails(clientes.length === 1),
                })
            }
            */
            await updateLineasPedidos(arrayARegistrar).then((data) => {
                if (data.error) {
                    estado.verError = true
                    estado.textoError = "Error guardando datos"
                    // cerrar dialogo salida si está abierto. //
                    estado.verDialogoSalida = false
                    setEstado({ ...estado })
                    console.log("error: ", data)
                }
                else {
                    // Se ha registrado correctamente. //
                    estado.modificaciones = false
                    //
                    // Poner Marca en Todas las Lineas de modificado a Falso
                    // Poner Existencias a Descontar a cero
                    estado.todasLineasPedidas.forEach(linea => {
                        linea.modificado = false
                        linea.Exis = 0
                    })
                    ////////////////////////////////////////////////////////
                    // Genera los Pdfs para los mails a los repartidores. //
                    generaPdfsAlbaranes({ envio: mercados, idioma: state.idioma }, { t: "jwt.token" }).then((data) => {
                        if (data.error) {
                            console.log('Error al generar Pdfs: ', data)
                        }
                        else {
                            if (data.length > 0) {
                                updateEntregas(data).then((data1) => {
                                    if (data1.error) {
                                        console.log("error: ", data1)
                                    }
                                    else {
                                        console.log('Registro OK ')
                                    }
                                })
                                // Registra las Entregas
                                /*
                                // Envía un mail a cada repartidor
                                data.map((fichero, i) => {
                                    enviaEmailAlbaran(fichero, state.emailUsuario, t("MisPedidos.CompraEnSareko"), t("MisPedidos.CompraEnSareko"), { t: "jwt.token" }).then((data) => {
                                        let error = ''
                                        if (data) {
                                            // Error
                                            if (data.rejected.length > 0) {
                                                data.rejectedErrors.map(rechazo => {
                                                    error = "Envio Rechazado: " + rechazo.recipient + "  Motivo: " + rechazo.response
                                                    // Return del map
                                                    return null
                                                })
                                            }
                                        }
                                        if (error === '') {
                                            toast.info('Email Albaran a: ' + state.emailUsuario, {
                                                position: "top-right",
                                                autoClose: 1000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                onClose: props => finEnviosMails(),
                                            })

                                            // Salta a pantalla Final - Email enviado
                                            console.log('Envio OK ', state.emailUsuario)
                                        }
                                        else {
                                            console.log('Envio Incorrecto: ', error)
                                        }
                                    })
                                    return null
                                })
                                */
                            }
                        }
                    })
                }
            })
        }
    }



    /*
    function finEnviosMails() {
        estado.verCalendario = true
        setEstado({ ...estado })
    }
    */


    const tituloYFiltros = (
        <Box sx={{ display: 'flex' }} height="50px">
            <Box sx={{ width: 2 / 6 }}>
                <Typography variant="verde30">
                    {t("MisPedidos.Pedidos")}
                </Typography>
            </Box>
            {!estado.verCalendario &&
                <Box sx={{ mt: 2, width: 4 / 6, display: 'flex' }} justifyContent="flex-end">
                    <Button
                        variant="contained"
                        startIcon={<CalendarMonthIcon />}
                        sx={{ mt: -1, ml: 5, mr: 3, width: 140, height: 30, bgcolor: ThemeGeroa.palette.azul.main, color: ThemeGeroa.palette.blanco.main }}
                        onClick={handleVolverAlCalendario}
                    >
                        {t("MisPedidos.Calendario")}
                    </Button>
                    <Button
                        variant="contained"
                        startIcon={<SaveIcon />}
                        sx={{ mt: -1, rm: 10, width: 120, height: 30 }}
                        onClick={handleGuardar}
                    >
                        <ToastContainer />
                        {t("MisPedidos.Guardar")}
                    </Button>
                </Box>
            }
        </Box>
    )




    const handleChangeProducto = (campo, iM, iC, iP) => event => {

        // Dato que ha cambiado. //
        let dato
        // Si es un Check. //
        if (event?.target?.checked !== undefined) {
            dato = event.target.checked
        } else {
            // Convertir en texto //
            dato = event + ""
        }
        //event.stopPropagation()
        let est = estado
        let numLinea = 0
        if (campo === "LinEnt") {
            // Si se quita todo el Nº, poner 0
            dato = dato === "" ? "0" : dato
            // impedir ceros por delatante 001... //
            //if (dato.substr(0,1) === '0' && dato.substr(1,1) !== '.') {
            //    dato = +dato +""
            //}
            est.lineasPedidos[iM].pedidos[iC].productos[iP].LinEnt = dato
            //
            numLinea = est.lineasPedidos[iM].pedidos[iC].productos[iP].ProContadorGeneral
            if (est.todasLineasPedidas[numLinea].LinEnt !== dato) {
                // Si hay que actualizar Existencias //
                if (est.todasLineasPedidas[numLinea].PedREx === 1) {
                    let Exis = est.todasLineasPedidas[numLinea].Exis
                    let LinEnt = est.todasLineasPedidas[numLinea].LinEnt
                    if (Exis === 0) {
                        // Sumar Cantidad Servida y Restar Dato Servida. //
                        est.todasLineasPedidas[numLinea].Exis = Number(LinEnt) - Number(dato)
                    } else {
                        // Sumar Existencias y Restar Dato Servida. //
                        est.todasLineasPedidas[numLinea].Exis = Exis + Number(LinEnt) - Number(dato)
                    }
                }
                est.todasLineasPedidas[numLinea].LinEnt = dato
                est.todasLineasPedidas[numLinea].modificado = true
                est.modificaciones = true
            }
        } else if (campo === "LinSer") {
            // Si se quita todo el Nº, poner 0
            dato = dato === "" ? "0" : dato
            // impedir ceros por delatante 001... //
            //if (dato.substr(0,1) === '0' && dato.substr(1,1) !== '.') {
            //    dato = +dato +""
            //}
            est.lineasPedidos[iM].pedidos[iC].productos[iP].LinSer = dato
            //
            numLinea = est.lineasPedidos[iM].pedidos[iC].productos[iP].ProContadorGeneral
            if (est.todasLineasPedidas[numLinea].LinSer !== dato) {
                est.todasLineasPedidas[numLinea].LinSer = dato
                est.todasLineasPedidas[numLinea].modificado = true
                est.modificaciones = true
            }
        } else if (campo === "LinLot") {
            est.lineasPedidos[iM].pedidos[iC].productos[iP].LinLot = dato
            //
            numLinea = est.lineasPedidos[iM].pedidos[iC].productos[iP].ProContadorGeneral
            if (est.todasLineasPedidas[numLinea].LinLot !== dato) {
                est.todasLineasPedidas[numLinea].LinLot = dato
                est.todasLineasPedidas[numLinea].modificado = true
                est.modificaciones = true
            }
        } else if (campo === "LinInc") {
            est.lineasPedidos[iM].pedidos[iC].productos[iP].LinInc = dato
            //
            numLinea = est.lineasPedidos[iM].pedidos[iC].productos[iP].ProContadorGeneral
            if (est.todasLineasPedidas[numLinea].LinInc !== dato) {
                est.todasLineasPedidas[numLinea].LinInc = dato
                est.todasLineasPedidas[numLinea].modificado = true
                est.modificaciones = true
            }
        }
        ///////////////////////////////////////
        // Marcas de Preparado en el MERCADO //
        ///////////////////////////////////////
        else if (campo === "MerEnt") {
            // Modificar las cantidades Entregadas 
            est.lineasPedidos[iM].MerEnt = dato
            // Activar todos los inferiores
            if (est.lineasPedidos[iM].MerEnt) {
                // Procesar Todos los Pedidos //
                est.lineasPedidos[iM].pedidos.forEach((ped, i) => {
                    // Si No hubo resta de existencias
                    let restarEx = (ped.PedREx === 0)
                    // Procesar todos los Productos //
                    ped.productos.forEach((pro, k) => {
                        if (pro.LinEnt === "") {
                            // SOLO Si estaba vacio
                            pro.LinEnt = pro.LinCan
                            console.log("Cantidad pedida: ", pro.LinCan, "     Cantidad que se quiere preparar: ", pro.LinEnt)
                            //
                            numLinea = pro.ProContadorGeneral
                            if (est.todasLineasPedidas[numLinea].LinEnt !== pro.LinEnt) {
                                est.todasLineasPedidas[numLinea].LinEnt = pro.LinEnt
                                est.todasLineasPedidas[numLinea].modificado = true
                                est.modificaciones = true
                            }
                            // Si el producto tiene control de Existencias y
                            // Si No hubo resta de Existencias o
                            // Si la Fecha de Pedido es Anterior a la Fecha de Inventario
                            if (pro.ProEIn > 0 && (restarEx || ped.PedFec < pro.ProFEi)) {
                                // Restar Cantidad Preparada. //
                                est.todasLineasPedidas[numLinea].Exis = - Number(pro.LinCan)
                            }
                        }
                    })
                })
            }
        }
        //////////////////////////////////////
        // Marcas de Servido en el MERCADO. //
        //////////////////////////////////////
        else if (campo === "MerSer") {
            // Modificar las cantidades Servidas y si es necesario la Entregadas 
            est.lineasPedidos[iM].MerSer = dato

            // Activar todos los inferiores
            if (est.lineasPedidos[iM].MerSer) {
                est.lineasPedidos[iM].pedidos.forEach((ped, i) => {
                    ped.productos.forEach((pro, k) => {
                        // Si la cantidad Entregada está vacía, poner 0. --
                        if (pro.LinEnt === "") {
                            pro.LinEnt = "0"
                            if (est.todasLineasPedidas[numLinea].LinEnt !== pro.LinEnt) {
                                est.todasLineasPedidas[numLinea].LinEnt = pro.LinEnt
                                est.todasLineasPedidas[numLinea].modificado = true
                                est.modificaciones = true
                            }
                        }
                        // Cambiar cantidad Servida. //
                        if (pro.LinSer === "") {
                            pro.LinSer = pro.LinEnt
                            //
                            numLinea = est.lineasPedidos[iM].pedidos[i].productos[k].ProContadorGeneral
                            if (est.todasLineasPedidas[numLinea].LinSer !== pro.LinSer) {
                                est.todasLineasPedidas[numLinea].LinSer = pro.LinSer
                                est.todasLineasPedidas[numLinea].modificado = true
                                est.modificaciones = true
                            }
                        }
                    })
                })
            } else {
                // Si la marca de Servido es falsa apagar emvio mail
                est.lineasPedidos[iM].MerEnv = false
                //est.lineasPedidos[iM].pedidos.forEach((ped) => {
                //    ped.PedEnv = 0
                //})
            }
        }
        //////////////////////////
        // Marcas de Email en el MERCADO
        else if (campo === "MerEnv") {
            est.lineasPedidos[iM].MerEnv = dato
            //est.lineasPedidos[iM].pedidos.forEach((ped) => {
            //    ped.PedEnv = dato ? 1 : 0
            //})
        }
        setEstado({ ...est })
    }



    function colorEstado(p) {
        let colorRetorno = "gris13"
        if (p.LinInc.trim() !== "") {
            colorRetorno = "rojo13"
        }
        return colorRetorno
    }



    function colorEstadoPrecio(p) {
        let colorRetorno = "gris13"
        if (p.LinPre !== p.LinTVa) {
            colorRetorno = "rojo13"
        }
        return colorRetorno
    }


    function colorCajaTexto(p) {
        let colorRetorno = "blue"
        if (p.LinInc.trim() !== "") {
            colorRetorno = "red"
        }
        return colorRetorno
    }





    const listaLineasPedidos = (
        estado.lineasPedidos.length > 0 ?

            <Grid sx={{ mt: 0 }}>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    sx={{ ml: 5, mr: 1, mt: 2, width: 160, height: 30, bgcolor: ThemeGeroa.palette.azul.main, color: ThemeGeroa.palette.blanco.main }}
                    onClick={() => setCrearPedido({ crear: true, mercado: null })}
                >
                    {t("MisPedidos.Crear")}
                </Button>
                {
                    crearPedido.crear &&
                    <DialogAñadirPedido
                        actualizador={actualizaCrearPedido}
                        m={crearPedido.mercado}
                        f={estado.dia}
                    />}
                <br />
                {crearProducto.crear &&
                    <DialogAñadirProducto
                        actualizador={actualizadorProductos}
                        p={crearProducto.pedido}
                        m={crearProducto.mercado}
                    />}
                <br />

                {cambiarFecha.cambiar &&
                    <DialogCambiarFecha
                        actualizador={actualizaCambiarFecha}
                        p={cambiarFecha.pedido}
                    />}
                <br />

                {!estado.verCalendario ?
                    <Typography variant="naranja18">
                        {t("MisPedidos.FechaEntrega")} {":  " + FechaLargaEsp(new Date(estado.dia), state.idioma)}
                    </Typography>
                    :
                    <Fragment />
                }
                {/* PAGINACION */}
                {estado.numPaginas > 1 &&
                    <Grid container justifyContent="center" >
                        <Pagination
                            sx={{ mt: 0, mb: 1, ml: 10 }}
                            count={estado.numPaginas}
                            page={estado.pagina}
                            onChange={handlePagina} />
                    </Grid>
                }
                {estado.lineasPedidos.map((m, indexM) => (
                    <Grid item key={"Mer" + m.MerCod + indexM + estado.pagina}>
                        <Grid container justifyContent="space-between">
                            <Grid>
                                <Typography variant="azul14">
                                    {m.MerDes}
                                </Typography>
                                <Button
                                    variant="contained"
                                    disabled={m.MerEnv}
                                    startIcon={<AddIcon />}
                                    sx={{ ml: 5, mr: 1, mt: 0, mb: 1, width: 120, height: 30, bgcolor: ThemeGeroa.palette.azul.main, color: ThemeGeroa.palette.blanco.main }}
                                    onClick={handleCrearPedido(m.MerCod)}
                                >
                                    {t("MisPedidos.Pedido")}
                                </Button>
                            </Grid>
                            <Grid sx={{ mt: -1 }}>
                                <Fragment>
                                    <Typography sx={{ ml: 0 }} variant="azul12" display="inline">
                                        {t("MisPedidos.Preparar")}:
                                    </Typography>
                                    <FormControlLabel
                                        sx={{ ml: 0 }}
                                        aria-label="Acknowledge"
                                        onClick={event => event.stopPropagation()}
                                        onFocus={event => event.stopPropagation()}
                                        control={<Checkbox
                                            id={"MerEnt" + indexM + m.MerCod}
                                            color="naranja"
                                            disabled={!comprobarLineasNoEntregadas(m.MerCod)}
                                            checked={!comprobarLineasNoEntregadas(m.MerCod)}
                                            onChange={handleChangeProducto("MerEnt", indexM, 0, 0)}
                                        />}
                                    />
                                </Fragment>

                                {state.esCoordinador && ( // Si es productor, no renderiza esto
                                    <Fragment>
                                        <Typography sx={{ ml: 0 }} variant="azul12" display="inline">
                                            {t("MisPedidos.Servir")}:
                                        </Typography>
                                        <FormControlLabel
                                            sx={{ ml: 0 }}
                                            aria-label="Acknowledge"
                                            onClick={event => event.stopPropagation()}
                                            onFocus={event => event.stopPropagation()}
                                            control={<Checkbox
                                                id={"MerSer" + indexM + m.MerCod}
                                                color="naranja"
                                                disabled={!comprobarLineasNoServidas(m.MerCod)}
                                                checked={!comprobarLineasNoServidas(m.MerCod)}
                                                onChange={handleChangeProducto("MerSer", indexM, 0, 0)}
                                            />}
                                        />
                                    </Fragment>
                                )}
                            </Grid>
                        </Grid>
                        {m.pedidos.map((p, indexP) => (
                            <Accordion
                                key={"Ped" + p.PedNum}
                                expanded={p.expandido}
                                onChange={handleChangeExpandido(indexM, indexP)}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                    <Grid container justifyContent="space-between">
                                        {/*<EmailAlbaran cliente={c} />*/}
                                        <Grid>
                                            <Typography variant={"verde14"} sx={{ ml: 5 }}>
                                                {p.PedNum}
                                            </Typography>
                                            <Typography variant="verde14" sx={{ ml: 2 }}>
                                                {p.PedRaz}
                                            </Typography>
                                            <Typography variant="verde12" sx={{ ml: 0 }}>
                                                {" (" + p.PedTel + ") (" + p.PedEma + ")"}
                                            </Typography>
                                            <Typography variant="verde12" sx={{ ml: 0 }}>
                                                {" (" + p.PedCli + ")"}
                                            </Typography>
                                            {p.PedFPa === "J" || p.PedFPa === "V" ?
                                                <Fragment>
                                                    <Typography variant={"gris13"} sx={{ ml: 1 }}>
                                                        {"(" + formaDePago(p.PedFPa) + ")"}
                                                    </Typography>
                                                </Fragment>
                                                :
                                                <Fragment />
                                            }
                                            {p.expandido ?
                                                <Fragment>
                                                    <Button
                                                        variant="contained"
                                                        disabled={m.MerEnv || p.PedEst === "FAC"}
                                                        startIcon={<AddIcon />}
                                                        sx={{ ml: 5, mr: 1, mt: -1, mb: 1, width: 120, height: 30, bgcolor: ThemeGeroa.palette.purpura.main, color: ThemeGeroa.palette.blanco.main }}
                                                        onClick={handleCrearProducto(p, m.MerCod)}
                                                    >
                                                        {t("MisPedidos.Añadir")}
                                                    </Button>

                                                    <Button
                                                        variant="contained"
                                                        disabled={m.MerEnv || (m.MerMon === 0 && !state.esProductor)}
                                                        startIcon={<CalendarMonthIcon />}
                                                        sx={{ ml: 5, mr: 1, mt: -1, mb: 1, width: 120, height: 30, bgcolor: ThemeGeroa.palette.purpura.main, color: ThemeGeroa.palette.blanco.main }}
                                                        onClick={handleCambiarFecha(p)}
                                                    >
                                                        {t("MisPedidos.Entrega")}
                                                    </Button>

                                                </Fragment>
                                                :
                                                <Fragment />
                                            }

                                        </Grid>
                                    </Grid>

                                </AccordionSummary>

                                <AccordionDetails>
                                    {p.productos.map((pr, indexPr) => (
                                        <Grid key={"Cab" + pr.LinNum + pr.LinArt + estado.Pagina}>
                                            <Divider sx={{ ml: 5 }} />
                                            <Grid item sx={{ mb: 1 }}>
                                                <Grid container justifyContent="space-between">
                                                    <Grid>
                                                        <Typography variant={colorEstado(pr)} sx={{ ml: 10 }}>
                                                            {pr.ProDes} {" (" + pr.LinArt + ")"}
                                                        </Typography>
                                                        {pr.ProExi <= 0 && pr.ProEIn > 0 ?
                                                            <Typography variant={"rojo13"} sx={{ ml: 10 }}>
                                                                {t("MisProductos.Agotado")}
                                                            </Typography>
                                                            :
                                                            <Fragment />
                                                        }
                                                    </Grid>

                                                </Grid>
                                                <Typography variant={colorEstado(pr)} sx={{ ml: 10 }}>
                                                    {t("MisPedidos.CantidadPedida") + ":"}
                                                </Typography>

                                                <FormControlLabel sx={{ ml: 0.5 }}
                                                    aria-label="Acknowledge"
                                                    onClick={event => event.stopPropagation()}
                                                    onFocus={event => event.stopPropagation()}
                                                    control={<CajaTexto
                                                        id={"LinCan" + pr.LinNum + pr.LinArt + pr.LinCan}
                                                        disabled={true}
                                                        tipo={"text"}
                                                        ancho={50}
                                                        color={colorCajaTexto(pr)}
                                                        valor={pr.LinCan}
                                                    />}

                                                />
                                                <Typography variant={colorEstado(pr)} sx={{ ml: -1 }}>
                                                    {" /"}
                                                </Typography>

                                                <Typography sx={{ ml: 0.5 }} variant={colorEstado(pr)} >
                                                    {t("MisPedidos.CantidadPreparada")}:
                                                </Typography>
                                                {/*console.log('aaaaa = ', pr)*/}
                                                <FormControlLabel sx={{ ml: 0.5 }}
                                                    aria-label="Acknowledge"
                                                    onClick={event => event.stopPropagation()}
                                                    onFocus={event => event.stopPropagation()}
                                                    control={<CajaTexto
                                                        id={"LinEnt" + pr.LinNum + pr.LinArt + pr.LinEnt}
                                                        disabled={pr.LinFac !== ""}
                                                        tipo={"number"}
                                                        ancho={50}
                                                        color={colorCajaTexto(pr)}
                                                        valor={pr.LinEnt}
                                                        accion={handleChangeProducto("LinEnt", indexM, indexP, indexPr)}
                                                    />}
                                                />
                                                <Typography sx={{ ml: -1 }} variant={colorEstado(pr)} >
                                                    {"/ " + t("MisPedidos.CantidadServida")}:
                                                </Typography>
                                                <FormControlLabel sx={{ ml: 0.5 }}
                                                    aria-label="Acknowledge"
                                                    onClick={event => event.stopPropagation()}
                                                    onFocus={event => event.stopPropagation()}
                                                    control={<CajaTexto
                                                        id={"LinSer" + pr.LinNum + pr.LinArt}
                                                        disabled={pr.LinFac !== ""}
                                                        tipo={"number"}
                                                        ancho={50}
                                                        color={colorCajaTexto(pr)}
                                                        valor={pr.LinSer}
                                                        accion={handleChangeProducto("LinSer", indexM, indexP, indexPr)}
                                                    />}
                                                />
                                                <Typography variant={colorEstadoPrecio(pr)} sx={{ ml: -1 }} display="inline">
                                                    {PrecioConIva(pr.LinPre, state.gruposIva, pr.ProIva)}
                                                </Typography>
                                                <Typography variant={colorEstadoPrecio(pr)} sx={{ ml: 0 }} display="inline">
                                                    {" € / " + state.uniAVer[pr.ProUni] + " = "}
                                                </Typography>
                                                <Typography variant={colorEstadoPrecio(pr)} sx={{ ml: 0 }} display="inline">
                                                    {PrecioLineaConIva(pr.LinPre, state.gruposIva, pr.ProIva, pr.LinSer)}
                                                </Typography>
                                                <Typography sx={{ ml: 1 }} variant={colorEstado(pr)} >
                                                    {t("MisPedidos.Lote")}:
                                                </Typography>
                                                <FormControlLabel sx={{ ml: 0.5 }}
                                                    aria-label="Acknowledge"
                                                    onClick={event => event.stopPropagation()}
                                                    onFocus={event => event.stopPropagation()}
                                                    control={<CajaTexto
                                                        id={"LinLot" + pr.LinNum + pr.LinArt}
                                                        disabled={pr.LinFac !== ""}
                                                        tipo={"text"}
                                                        ancho={120}
                                                        color={colorCajaTexto(pr)}
                                                        valor={pr.LinLot}
                                                        accion={handleChangeProducto("LinLot", indexM, indexP, indexPr)}
                                                    />}
                                                />
                                                <Typography sx={{ ml: 1 }} variant={colorEstado(pr)} >
                                                    {t("MisPedidos.Inc")}:
                                                </Typography>
                                                <FormControlLabel sx={{ ml: 0.5 }}
                                                    aria-label="Acknowledge"
                                                    onClick={event => event.stopPropagation()}
                                                    onFocus={event => event.stopPropagation()}
                                                    control={<CajaTexto
                                                        id={"LinInc" + pr.LinNum + pr.LinArt}
                                                        disabled={pr.LinFac !== ""}
                                                        tipo={"text"}
                                                        ancho={160}
                                                        color={colorCajaTexto(pr)}
                                                        valor={pr.LinInc}
                                                        accion={handleChangeProducto("LinInc", indexM, indexP, indexPr)}
                                                    />}
                                                />
                                            </Grid>
                                        </Grid>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>
                ))}
                <Divider />
                <Grid container sx={{ mt: 2 }} justifyContent="right" >
                    <Button
                        variant="contained"
                        startIcon={<SaveIcon />}
                        sx={{ mt: 0, mr: 2, width: 120, height: 30 }}
                        onClick={handleGuardar}
                    >
                        {t("MisPedidos.Guardar")}
                    </Button>
                </Grid>


                {/* PAGINACION */}
                {estado.numPaginas > 1 &&
                    <Grid container justifyContent="center" >
                        <Pagination
                            sx={{ mt: 0, mb: 1, ml: 10 }}
                            count={estado.numPaginas}
                            page={estado.pagina}
                            onChange={handlePagina} />
                    </Grid>
                }
            </Grid>
            :
            <Grid sx={{ mt: 0 }}>
                {
                    crearPedido.crear &&
                    <DialogAñadirPedido
                        actualizador={actualizaCrearPedido}
                        m={crearPedido.mercado}
                        f={estado.dia}
                    />}
                <Typography variant="h6" color="textSecondary" sx={{ mt: 2 }}>
                    No tiene ningún pedido creado para este día.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    sx={{ mt: 2 }}
                    onClick={handleCrearPedido(null)}
                >
                    {t("MisPedidos.Crear")}
                </Button>
            </Grid>
    )





    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Container sx={{ mt: 1.5 }}>
                {tituloYFiltros}
                <MensajeCabecera />

                <ToastContainer />
                {estado.verError &&
                    <DialogoError
                        accion={handleCerrarError}
                        textoError={estado.textoError}
                    />
                }
                {estado.verDialogoSalida &&
                    <DialogSalida actualizador={actualizadorSalida} />
                }
                {estado.verCalendario ?
                    <MiCalendario
                        estados={estado}
                        funcionEstados={setEstado}
                        funcionVolver={handleVolverAlCalendario} />
                    : listaLineasPedidos
                }
                {/*listaPedidos*/}
                <br />
            </Container>
        </ThemeProvider>
    );
}

export default withTranslation()(MisPedidos)