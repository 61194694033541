import React, { Fragment } from "react";

import CssBaseline from '@mui/material/CssBaseline';
import FormControl from '@mui/material/FormControl'
import TextField from "@mui/material/TextField";

export default function CajaTexto({ ...props }) {
    // Esta función se encargará de manejar el cambio en el campo de texto
    const controlaTexto = (event) => {
        let valor = event.target.value;
        console.log("Valor en caja texto: ", props.valor)
        console.log("Valor en caja texto: ", event)
        // Si el tipo es number, asegurarse de que solo haya números válidos
        if (props.tipo === "number") {
            if (isNaN(Number(valor))) {
                valor = 0; // Si no es un número válido, lo cambiamos a 0
            } else {
                // Eliminamos ceros a la izquierda si el valor no tiene decimales
                if (valor.substr(0, 1) === '0' && valor.substr(1, 1) !== '.') {
                    valor = +valor + ""; // Convierte el número en una cadena de texto sin ceros a la izquierda
                }
            }
        }

        // Si es texto, no hacemos ningún cambio, solo pasamos el valor como está
        if (props.tipo === "text") {
            valor = valor;
        }

        // Llamamos a la acción que se pasó como propiedad y le pasamos el valor actualizado
        props.accion(valor);
    };

    // Definir los estilos de la caja de texto
    const InputCSS = {
        width: props.ancho,
        "& .MuiInputBase-root": {
            marginLeft: "5px",
            height: 20,
            color: props.color || 'blue', // Usamos un color por defecto si no se pasa uno
            fontsize: 12,
            fontWeight: 700,
        },
        "& .MuiInputBase-input": {
            padding: '4px 6px 4px 6px',
            textAlign: props.tipo === "number" ? 'right' : 'left',
        },
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none",
        },
        "& input[type=number]": {
            MozAppearance: "textfield",
        },
    };

    return (
        <Fragment>
            <CssBaseline />
            <FormControl>
                <TextField
                    id={"Txt_" + props.id}
                    label=""
                    autoFocus={props.foco}
                    type={props.tipo} // "text" o "number"
                    sx={InputCSS}
                    disabled={props.disabled}
                    value={props.valor || ""}
                    onKeyDown={(evt) =>
                        props.tipo === "number" && ".-Ee".includes(evt.key) && evt.preventDefault()
                    }
                    onChange={controlaTexto} // Aquí es donde se pasa el evento correctamente
                />
            </FormControl>
        </Fragment>
    );
}
