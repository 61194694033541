
const config_sareko = {
  port: process.env.REACT_APP_MY_PORT,
  //
  rutaApis: "https://sareko.net/"+process.env.REACT_APP_MY_PORT,
  rutaFotosProductos: "https://sareko.net/"+process.env.REACT_APP_RUTA_FOTOS+"/productos/",
  rutaCarpetaFicherosFacturasProductor: "https://sareko.net/"+process.env.REACT_APP_RUTA_FICHEROS+"/_pdfProductor/",
  rutaCarpetaFicherosEntregas: "https://sareko.net/"+process.env.REACT_APP_RUTA_FICHEROS+"/_pdfReparto/",
  
  //
  jwtSecret: process.env.JWT_SECRET || "1223435688",
  timeToken: 9999,
  //
  passMapas: process.env.REACT_APP_MAPAS,
  notificaciones: true,
  tiempoEntreNotificaciones: 10000 // 10 segundos
}

const config_geroakoa = {
  port: process.env.REACT_APP_MY_PORT,
  //
  rutaApis: "https://sareko.net:"+process.env.REACT_APP_MY_PORT,
  rutaFotosProductos: "https://sareko.net/"+process.env.REACT_APP_RUTA_FOTOS+"/productos/",
  rutaCarpetaFicherosFacturasProductor: "https://sareko.net/"+process.env.REACT_APP_RUTA_FICHEROS+"/_pdfProductor/",
  rutaCarpetaFicherosEntregas: "https://sareko.net/"+process.env.REACT_APP_RUTA_FICHEROS+"/_pdfReparto/",
  
  //
  jwtSecret: process.env.JWT_SECRET || "1223435688",
  timeToken: 9999,
  //
  passMapas: process.env.REACT_APP_MAPAS,
  notificaciones: true,
  tiempoEntreNotificaciones: 10000 // 10 segundos
}



const config_cashflow = {
  port: process.env.REACT_APP_MY_PORT,
  //
  rutaApis: "https://cashflow.eus/"+process.env.REACT_APP_MY_PORT,
  rutaFotosProductos: "https://cashflow.eus/"+process.env.REACT_APP_RUTA_FOTOS+"/productos/",
  rutaCarpetaFicherosFacturasProductor: "https://cashflow.eus/"+process.env.REACT_APP_RUTA_FICHEROS+"/_pdfProductor/",
  rutaCarpetaFicherosEntregas: "https://cashflow.eus/"+process.env.REACT_APP_RUTA_FICHEROS+"/_pdfReparto/",
  
  //
  jwtSecret: process.env.JWT_SECRET || "1223435688",
  timeToken: 9999,
  //
  passMapas: process.env.REACT_APP_MAPAS,
  notificaciones: true,
  tiempoEntreNotificaciones: 10000 // 10 segundos
}

const config_dev = {
  port: process.env.REACT_APP_MY_PORT,
  //
  rutaApis: "http://localhost"+process.env.REACT_APP_MY_PORT,
  rutaFotosProductos: "http://localhost/"+process.env.REACT_APP_RUTA_FOTOS+"/productos/",
  rutaCarpetaFicherosFacturasProductor: "http://localhost/"+process.env.REACT_APP_RUTA_FICHEROS+"/_pdfProductor/",
  rutaCarpetaFicherosEntregas: "http://localhost/"+process.env.REACT_APP_RUTA_FICHEROS+"/_pdfReparto/",
  
  //
  jwtSecret: process.env.JWT_SECRET || "1223435688",
  timeToken: 9999,
  //
  passMapas: process.env.REACT_APP_MAPAS,
  notificaciones: true,
  tiempoEntreNotificaciones: 10000 // 10 segundos
}



let config
//
if (process.env.REACT_APP_MY_ENV === "Cashflow") {
  config = config_cashflow
} else if(process.env.REACT_APP_MY_ENV === "Geroakoa") {
  config = config_geroakoa
} else if (process.env.REACT_APP_MY_ENV === "Sareko") {
  config = config_sareko
  // Ocultar logs
  console.log = function () {};
} else if(process.env.REACT_APP_MY_ENV  === "dev"){
  config = config_dev
}


export default config