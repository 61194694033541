import React, { useState, useEffect, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { useStore } from 'react-create-use-store';

import { ThemeProvider, alpha, styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
//import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from "@mui/material/FormControl";
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from "@mui/material/Grid";
import InputBase from '@mui/material/InputBase';
import { InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Select from "@mui/material/Select";

import SearchIcon from '@mui/icons-material/Search';

import { BuscaIndice, FechaAaaaMmDd, PrecioLineaConIva, RedondeaPrecio, StrZero } from '../../config/funcionesPropias.js'
import ThemeGeroa from './../../ThemeGeroa.js'
import store from './../../adderStore.js'
import CajaTexto from './../../components/CajaTexto.js';


import { listaProductosProveedor } from './../../apis/api-producto.js'
import { listaFPagoMercados, listaMercadosProvCoor, detalleMercado, getProductosMercado } from '../../apis/api-mercado.js';
import { listaClientesMercado } from './../../apis/api-cliente.js'
import { crearNuevoPedido, crearPedidos } from '../../apis/api-pedido.js';


const DialogAñadirPedido = ({ t, ...props }) => {

    useEffect(() => {
        console.log("Mercado pasado por props: ", props.m)
        if (props.m) {
            setMercadoSeleccionado(props.m); // Si el prop 'm' está presente, actualizamos el valor
        }
    }, [props.m]);

    useEffect(() => {
        estado.FPagoM = [];
    }, []);

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
            marginTop: theme.spacing(3),
        },
        '& .MuiInputBase-input': {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
            border: '1px solid',
            borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
            fontSize: 13,
            width: 'auto',
            padding: '2px 2px 2px 14px',
            transition: theme.transitions.create([
                'border-color',
                'background-color',
                'box-shadow',
            ]),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    }));

    const { state, /*actions*/ } = useStore(store);
    const codigoMercado = props.m
    const fechaEntrega = new Date(props.f)
    const mercado = state.mercadosProveedor.filter((m) => {
        return (m.MerCod === codigoMercado)
    })
    const nombreMercado = mercado.length > 0 ? (state.idioma === "Es" ? mercado[0]?.MerDesC : mercado[0]?.MerDesE) : "Mercado no encontrado"


    const [formasPago, setFormasPago] = useState([]);
    const [formaPagoSeleccionada, setFormaPagoSeleccionada] = useState("");
    const [estado, setEstado] = useState(
        {
            carga: true,
            mensaje: "Cargando productos...",
            //verDialogo: true,
            buscar: false,
            busCli: "",
            cliente: {},
            clientes: [],
            productos: [],
            fPago: "",
            FPagoM: [],
            PedGas: ""
        }
    )
    const [productosEstado, setProductosEstado] = useState([]);

    /*
    useEffect(() => {
        if (estado.carga) {
            loadProductos()
            estado.carga = false
            estado.mensaje = ""
        }
    })

    async function loadProductos() {
        console.log("Entrando a loadProductos")
        // Cargar las formas de pago del mercado
        await listaFPagoMercados({ mercado: codigoMercado }).then((data) => {
            if (data.error) {
                console.log("Error:", data)
            } else {
                if (data.length > 0) {
                    setEstado(prevState => ({
                        ...prevState,
                        fPago: data[0].ForTip,
                        FPagoM: data
                    }));
                }
            }
        });
        // Cargar productos
        await listaProductosProveedor({ proveedor: state.codProveedor, filtro: 1, desde: 0, cuantos: 10000, idioma: state.idioma }, { texto: "" }).then((data) => {
            if (data.error) {
                console.log("Error:", data)
            } else {
                let productosMercado = data.filter((p) => {
                    let ind = BuscaIndice(p.Mercados, "MerCod", codigoMercado);
                    return ind !== -1 && p.Mercados[ind].TarVis === 1;
                });
    
                let datos = productosMercado.map(paa => {
                    let tarifa = paa.Mercados.find(m => m.MerCod === codigoMercado);
                    return {
                        LinArt: paa.ProCod,
                        LinLot: paa.ProLot,
                        LinDes: state.idioma === "Es" ? paa.ProDesC : paa.ProDesE,
                        LinPro: state.codProveedor,
                        LinPre: tarifa?.TarVal || 0,
                        LinIva: paa.ProIva,
                        ProExi: Number(paa.ProExi),
                        ProEIn: Number(paa.ProEIn)
                    };
                });
    
                // Actualiza el estado con los nuevos productos
                setEstado(prevState => ({
                    ...prevState,
                    productos: datos
                }));
            }
        });
    }
*/
    const handleAbandonar = () => {
        props.actualizador(false)
    }

    /*
    const handleGuardar = () => {
        console.log("Entrando a handleGuardar.")
        let cli = estado.cliente
        let lineas = estado.productos.filter((p) => p.LinCan > 0)
        // Poner a Todas las lineas las Existencias a descontar. //
        lineas.forEach(l => {
            // Si hay control de Existencias. //
            if (l.ProEIn > 0) {
                console.log("Linea añadida")
                l.LinExi = Number(l.LinCan)
            }
        })
        console.log("Cliente: " + JSON.stringify(cli))
        if (cli.CliCod !== undefined && lineas.length > 0) {
            console.log("Condicion OK.")
            setEstado({ ...estado, mensaje: "Registrando..." })
            // Convertir a Cadena AAAA-MM-DD //
            let hoy = new Date()
            hoy = StrZero(hoy.getFullYear(), 4) + "-" +
                StrZero((hoy.getMonth() + 1), 2) + "-" +
                StrZero(hoy.getDate(), 2) + " " +
                StrZero(hoy.getHours(), 2) + ":" +
                StrZero(hoy.getMinutes(), 2) // +":"
            //StrZero(fec.getSeconds(), 2)
            // Poner como fecha de Entrega la del calendario. //
            let fEn = FechaAaaaMmDd(fechaEntrega) + " 12:00"
            let pedido = {
                PedFec: hoy,
                PedCli: cli.CliCod,
                PedMer: codigoMercado,
                PedFPa: estado.fPago,
                PedFEn: fEn,
                PedFFa: "",
                PedREx: "1",
                PedPDF: "0",
                PedTEn: "D",
                PedEma: cli.CliEma,
                PedRaz: cli.CliNom,
                PedCal: cli.DirCal,
                PedPob: cli.DirPob,
                PedCpo: cli.DirCpo,
                PedPro: cli.DirPro,
                PedTel: cli.CliTel,
                PedRep: "",
                PedRNo: "",
                PedREm: "",
                PedGas: estado.PedGas,
                productos: lineas
            }
            crearNuevoPedido(pedido).then((data) => {
                if (data.error) {
                    // Se podría dar un mensaje por si falla. //
                    console.log("Error:", data)
                }
                else {
                    // Ha creado el pedido. //
                    props.actualizador(true)
                }
            })
        }
        // Salirse
    }

*/


    const handleChangePedido = (campo, i) => event => {
        let est = estado
        //
        if (campo === "Cantidad") {
            const valor = event.value;
            const productosActualizados = [...estado.productos];
            productosActualizados[i].cantidad = parseInt(valor) || 0;
            setEstado(prev => ({
                ...prev,
                productos: productosActualizados
            }));
            console.log("Añadiendo productos a lineas.")
        } else if (campo === "BusCli") {
            // Si teclea no busca. //
            est.buscar = false
            est.busCli = event
        } else if (campo === "SelCli") {
            // Localizar en el array el elemento seleccionado //
            let sel = est.clientes.filter((c) => {
                return (c.DirCod + c.DirNum === event.target.value)
            })
            // guardar todos los datos del cliente. //
            est.cliente = sel.length > 0 ? sel[0] : {}
        } else if (campo === "SelFor") {
            // guardar la forma de pago del mercado. //
            console.log("Forma de pago seleccionada: ", event.target.value)
            estado.fPago = event.target.value
        } else if (campo === "PedGas") {
            est.PedGas = event
        }
        //
        setEstado({ ...est })
    }

    const handleBuscarCliente = async event => {
        console.log("Entrando a buscar cliente")
        if (estado.busCli !== "") {
            console.log("Variables para llama a busqueda cientes. Mercado: ", codigoMercado, "  texto: ", estado.busCli)
            await listaClientesMercado({ mercado: mercadoSeleccionado }, { texto: estado.busCli }).then((data) => {
                if (data.error) {
                    console.log("Error:", data)
                }
                else {
                    console.log("Data correcta en buscar cliente: ", data)
                    //console.log('Clientes = ', data)
                    // Actualizar estado. //
                    estado.buscar = true
                    // Poner el primer cliente. //
                    estado.cliente = data.length > 0 ? data[0] : ""
                    estado.clientes = data
                    // Fuerza Renderizado
                    setEstado({ ...estado })
                }
            })
        }
    }

    /*****************************************************
 * Seleccionador de mercado
 *****************************************************/
    const [mercadoSeleccionado, setMercadoSeleccionado] = useState(''); // Estado para el mercado seleccionado
    const [mercadosDetalles, setMercadosDetalles] = useState([]);

    // ⚡ Actualiza el estado del mercado seleccionado y limpia los productos
    const handleChangeMercado = (event) => {
        const nuevoMercado = event.target.value;
        setMercadoSeleccionado(nuevoMercado);
        setProductosEstado([]);  // Vaciamos productosEstado
        setFormasPago([]); // Limpia las formas de pago
        setFormaPagoSeleccionada(""); // Limpia la forma de pago seleccionada

        // Buscar el mercado seleccionado
        const mercado = mercadosDetalles.find(m => m.codigo === nuevoMercado);

        // Actualizar las formas de pago
        setFormasPago(mercado ? mercado.formasPago : []);

        console.log("Formas de pago actualizadas: ", formasPago);

        // 🔄 Limpiar productos al cambiar de mercado:
        // Esto previene que se muestren productos del mercado anterior mientras se cargan los nuevos.
        setEstado(prevState => ({
            ...prevState,
            productos: [],
            carga: true
        }));
    };


    const handleChangeFormaPago = (event) => {
        setFormaPagoSeleccionada(event.target.value);
    };


    /*****************************************************
     * useEffect: Cargar productos cuando cambia el mercado seleccionado
     *****************************************************/
    useEffect(() => {
        // 🚀 Función asíncrona para cargar productos del mercado seleccionado
        const cargarProductos = async () => {
            // ⛔ Si no hay mercado seleccionado, no hace falta cargar nada
            if (!mercadoSeleccionado) return;

            try {
                console.log("Mercado seleccionado: ", mercadoSeleccionado)
                // 🔍 Se llama a la API para obtener los productos del mercado seleccionado
                const productos = await getProductosMercado(mercadoSeleccionado);
                console.log("Productos cargados:", productos);

                // 🧩 Se actualiza el estado con los productos obtenidos
                setEstado(prevState => ({
                    ...prevState,
                    productos: productos,
                }));
            } catch (error) {
                // ⚠️ Manejo de errores: si falla la llamada a la API, se registra en la consola
                console.error("Error al cargar los productos:", error);
            }
        };

        cargarProductos(); // 🔄 Se ejecuta la función asíncrona para cargar los productos
        setEstado(prevState => ({
            ...prevState,
            carga: false
        }));
    }, [mercadoSeleccionado]); // 🔔 Se ejecuta cuando cambia el mercado seleccionado


    useEffect(() => {
        // Si no hay un mercado seleccionado, no hacemos nada
        if (!mercadoSeleccionado) return;
    
        // Buscar el mercado seleccionado en mercadosDetalles
        const mercado = mercadosDetalles.find((m) => m.codigo === mercadoSeleccionado);
    
        if (mercado) {
            // Actualizar las formas de pago con las del mercado seleccionado
            setFormasPago(mercado.formasPago);
    
            // Establecer la primera forma de pago como predeterminada
            if (mercado.formasPago.length > 0) {
                setFormaPagoSeleccionada(mercado.formasPago[0].codigo);
            } else {
                setFormaPagoSeleccionada(""); // Si no hay formas de pago, limpiar el estado
            }
        } else {
            // Si no se encuentra el mercado, limpiar las formas de pago
            setFormasPago([]);
            setFormaPagoSeleccionada("");
        }
    }, [mercadoSeleccionado, mercadosDetalles]); // Se ejecuta cuando cambia mercadoSeleccionado o mercadosDetalles

    /*****************************************************
     * useEffect: Cargar detalles de los mercados al cargar el componente
     *****************************************************/
    useEffect(() => {
        // 🚀 Función asíncrona para obtener detalles de los mercados disponibles
        const obtenerDetalles = async () => {
            const detalles = [];
            console.log("Mercados proveedor: ", state.mercadosProveedor);

            // 🔄 Se recorren los códigos de los mercados asociados al proveedor
            for (const codigo of state.mercadosProveedor) {
                console.log("Codigo de mercado: ", codigo.MerCod);

                // 📡 Se obtiene el detalle de cada mercado mediante una llamada a la API
                const detallesMercado = await detalleMercado(codigo.MerCod);
                console.log("Detalle mercado: ", detallesMercado);

                // ✅ Si se obtuvo correctamente el detalle, se añade al array
                if (detallesMercado) {
                    console.log("Codigo de mercado devuelto: ", detallesMercado.MerCod);
                    const mercado = {
                        codigo: detallesMercado.MerCod,
                        nombre: detallesMercado.MerDesC,
                        puntosRecogida: [],  // Inicializamos el array de puntos de recogida
                        formasPago: [],
                    };

                    estado.FPagoM = []
                    // Loop para agregar los puntos de recogida
                    detallesMercado.puntosRecogida.forEach(detalleMercado => {
                        mercado.puntosRecogida.push({
                            codigo: detalleMercado.PrMCod,
                            nombre: detalleMercado.PrMNom
                        });
                    });
                    console.log("Formas de pago: ", detallesMercado.formasPago)
                    detallesMercado.formasPago.forEach(detalleMercado => {
                        mercado.formasPago.push({
                            codigo: detalleMercado.ForTip,
                            nombre: state.idioma === "Es" ? detalleMercado.ForDesC : detalleMercado.ForDesE
                        });
                    });
                    // estado.fPago = estado.FPagoM[0].codigo
                    console.log("Forma pago actualizada: ", estado.fPago)
                    console.log("Formas de pago en estado: ", estado.FPagoM)

                    detalles.push(mercado);
                }
            }

            // 🧩 Se actualiza el estado con los detalles de todos los mercados
            setMercadosDetalles(detalles);
            console.log('mercadosDetalles:', detalles);
        };

        obtenerDetalles(); // 🔄 Se ejecuta la función asíncrona al cargar el componente
    }, []); // 🔔 Solo se ejecuta una vez, al cargar el componente


    /*****************************************************
     * Capturar datos del componente y crear pedido
     *****************************************************/
    const construirPedido = () => {
        // Obtener fecha actual en formato adecuado
        const fechaActual = new Date().toISOString().slice(0, 19).replace('T', ' ');
        const cliente = estado.cliente

        // Construir el JSON del pedido
        console.log("Forma de pago: ", estado.fPago)
        const pedido = {
            orders: [
                {
                    fechaPedido: fechaActual,
                    codigoCliente: cliente.CliCod,
                    nombreCliente: cliente.CliNom,
                    emailCliente: cliente.CliEma,
                    telefonoCliente: cliente.CliTel,
                    tipoEntrega: "D",
                    puntoEntrega: {
                        calle: cliente.DirCal,
                        localidad: cliente.DirPob,
                        codigoPostal: cliente.DirCpo,
                        provincia: cliente.DirPro
                    },
                    productos: productosEstado // Usamos el estado de productos
                        .filter(producto => producto.quantity > 0) // Solo incluir productos con cantidad mayor a 0
                        .map(producto => ({
                            producto: producto.product, // ID del producto
                            cantidad: producto.quantity, // Cantidad
                        })),
                    mercado: mercadoSeleccionado,
                    formaPago: formaPagoSeleccionada === "" || !formaPagoSeleccionada || formaPagoSeleccionada === null ? formasPago[0].codigo : formaPagoSeleccionada,
                    gastos: estado.PedGas || 0,
                    fechaEntrega: fechaEntrega,
                    restaExistencias: true,
                    idioma: state.idioma
                }
            ]
        };

        console.log("Pedido construido:", pedido);

        return pedido;
    };

    console.log("Productos para generar JSON: ", JSON.stringify(productosEstado))
    const handleGuardar = async () => {
        const jsonPedido = construirPedido();

        console.log("JSON del pedido:", JSON.stringify(jsonPedido));

        // Llamamos a crearPedidos y esperamos la respuesta
        const isPedidoCreado = await crearPedidos(jsonPedido);

        if (isPedidoCreado) {
            // Si el pedido se crea correctamente, mostramos el diálogo
            setDialogoAbierto(true);
            props.actualizador(true);
        } else {
            console.log('Hubo un error al crear el pedido');
        }
    };


    const handleChangeCantidad = (codigoProducto, i) => (event) => {
        estado.productos[i].LinCan = event
        console.log("Evento: ", event)
        const nuevaCantidad = parseInt(event) || 0; // Asegura que si no es un número, se pone 0.
        console.log("Nueva cantidad: ", nuevaCantidad)

        // Mostramos el producto y la nueva cantidad en consola
        console.log(`Agregando/actualizando producto con código: ${codigoProducto}`);
        console.log(`Nueva cantidad: ${nuevaCantidad}`);

        setProductosEstado((prevProductosEstado) => {
            // Verificar si ya existe el producto con el código
            const productoExistente = prevProductosEstado.find((producto) => producto.product === codigoProducto);

            if (productoExistente) {
                if (nuevaCantidad === 0) {
                    // Si la cantidad es 0, eliminamos el producto
                    console.log(`Cantidad 0 detectada, eliminando producto con código ${codigoProducto}.`);
                    const productosActualizados = prevProductosEstado.filter((producto) => producto.product !== codigoProducto);
                    console.log("Productos actualizados después de eliminar el producto:", productosActualizados);
                    return productosActualizados;
                } else {
                    // Si el producto ya existe, actualizamos la cantidad
                    console.log(`Producto con código ${codigoProducto} ya existe, actualizando cantidad.`);
                    const productosActualizados = prevProductosEstado.map((producto) =>
                        producto.product === codigoProducto
                            ? { ...producto, quantity: nuevaCantidad }
                            : producto
                    );
                    console.log("Productos actualizados después de la actualización:", productosActualizados);
                    return productosActualizados;
                }
            } else {
                // Si el producto no existe, lo agregamos como nuevo
                console.log(`Producto con código ${codigoProducto} no existe, añadiendo nuevo producto.`);
                const productosActualizados = [
                    ...prevProductosEstado,
                    { product: codigoProducto, quantity: nuevaCantidad }, // Añadimos el nuevo producto con la cantidad
                ];
                console.log("Productos actualizados después de añadir uno nuevo:", productosActualizados);
                return productosActualizados;
            }
        });
    };


    const [dialogoAbierto, setDialogoAbierto] = useState(false);

    const handleCerrarDialogo = () => {
        // Cerrar el diálogo y recargar la página
        setDialogoAbierto(false);
        window.location.reload(); // Recargar la página
    };



    const traducirFormaPago = (codigo, idioma) => {
        if (idioma === 'Es') {
            switch (codigo) {
                case 'R': return 'Recibo';
                case 'J': return 'Tarjeta';
                case 'T': return 'Transferencia';
                case 'V': return 'Tpv';
                case 'P': return 'Productor';
                default: return 'Desconocido';
            }
        } else {
            switch (codigo) {
                case 'R': return 'Hile amaieran helbideratzea';
                case 'J': return 'Txartela';
                case 'T': return 'Transferentzia';
                case 'V': return 'Tpv';
                case 'P': return 'Ekoizlea';
                default: return 'Ezezaguna';
            }
        }
    };



    return (
        <ThemeProvider theme={ThemeGeroa}>

            <Dialog open={dialogoAbierto} onClose={handleCerrarDialogo} maxWidth="md">
                <DialogTitle>
                    <Typography variant="azul15">¡Pedido Guardado!</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="gris13">
                        El pedido se ha guardado correctamente.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        sx={{ mb: 1, mr: 2, width: 110, height: 30 }}
                        onClick={handleCerrarDialogo}
                    >
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={open} maxWidth="md">
                <DialogTitle>
                    <Typography variant="azul15">{t("MisProductos.Añadir")}</Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {/* Dropdown para seleccionar el mercado */}
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="mercado-label">{t("MisPedidos.SeleccioneMercado")}</InputLabel>
                        <Select
                            labelId="mercado-label"
                            value={mercadoSeleccionado}
                            onChange={handleChangeMercado}
                        >
                            {mercadosDetalles.map((mercado, index) => (
                                <MenuItem key={index} value={mercado.codigo}>
                                    {mercado.nombre}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogContent>
                    <Typography variant="gris13" >
                        {t("MisPedidos.Cliente")}:
                    </Typography>
                    <FormControlLabel sx={{ ml: 1 }}
                        aria-label="Acknowledge"
                        onClick={event => event.stopPropagation()}
                        onFocus={event => event.stopPropagation()}
                        control={<CajaTexto
                            id={"BusCli"}
                            foco={true}
                            tipo={"text"}
                            ancho={180}
                            valor={estado.busCli}
                            accion={handleChangePedido("BusCli")}
                        />}
                    />
                    <Button
                        variant="contained"
                        startIcon={<SearchIcon />}
                        sx={{ mt: 0, height: 25, bgcolor: ThemeGeroa.palette.azul.main, color: ThemeGeroa.palette.blanco.main }}
                        onClick={handleBuscarCliente}
                    />
                    {estado.clientes.length > 0 ?
                        <FormControl
                            sx={{ ml: 1 }}
                            onClick={event => event.stopPropagation()}
                            onFocus={event => event.stopPropagation()}
                        >
                            <Select
                                id={"Clientes"}
                                //autoFocus
                                value={estado.cliente.DirCod + estado.cliente.DirNum}
                                //onChange={handleFiltroIvas}
                                onChange={handleChangePedido("SelCli")}
                                input={<BootstrapInput id={"SelCli"} />}
                            >
                                {estado.clientes.map((c, i) => (
                                    <MenuItem value={c.DirCod + c.DirNum} key={c.DirCod + c.DirNum}> {c.DirCod + " " + c.DirNom + " (" + c.DirCal + " - " + c.DirPob + ")"} </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        :
                        <Typography variant="rojo13" sx={{ ml: 1 }} >
                            {estado.buscar ? t("MisPedidos.NoEncontrado") : t("MisPedidos.SeleccionarCliente")}
                        </Typography>
                    }

                    <Grid item sx={{ mt: 1 }}>
                        <Typography variant="gris13">{t("MisPedidos.FormaPago")}:</Typography>
                        {formasPago.length > 0 ? (
                            <FormControl
                                sx={{ ml: 1, mb: 0 }}
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                            >
                                <Select
                                    id="FormasPago"
                                    value={formaPagoSeleccionada}
                                    onChange={handleChangeFormaPago}
                                    input={<BootstrapInput id="SelFor" />}
                                >
                                    {formasPago.map((f) => (
                                        <MenuItem key={f.codigo} value={f.codigo}>
                                            {traducirFormaPago(f.codigo, state.idioma)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : (
                            <Typography variant="rojo13" sx={{ ml: 1 }}>
                                {t("MisPedidos.NoFormasPago")}
                            </Typography>
                        )}
                    </Grid>



                    <Grid item>
                        <Typography variant="gris13" >
                            {t("MisPedidos.Gastos")}:
                        </Typography>
                        <FormControlLabel sx={{ ml: 1 }}
                            aria-label="Acknowledge"
                            onClick={event => event.stopPropagation()}
                            onFocus={event => event.stopPropagation()}
                            control={<CajaTexto
                                id={"PedGas"}
                                tipo={"number"}
                                ancho={100}
                                valor={estado.PedGas}
                                accion={handleChangePedido("PedGas")}
                            />}
                        />
                    </Grid>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: 80 }}>{t("MisPedidos.Cantidad")}</TableCell>
                                    <TableCell>{t("MisPedidos.Producto")}</TableCell>
                                    <TableCell sx={{ width: 120 }} align="right">{t("MisPedidos.PrecioPrecioIva")}</TableCell>
                                    <TableCell align="right">{t("MisPedidos.Existencias")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {estado.productos.map((producto, i) => (
                                    <TableRow
                                        key={"Prod" + producto.codigoProducto + i}
                                        sx={{
                                            '&:last-child td, &:last-child th': {
                                                border: 0,
                                                paddingTop: 1, paddingBottom: 1
                                            }
                                        }}
                                    >
                                        <TableCell sx={{ paddingTop: 0.2, paddingBottom: 0.2 }}>
                                            <FormControlLabel
                                                sx={{ width: 40 }}
                                                aria-label="Acknowledge"
                                                onClick={event => event.stopPropagation()}
                                                onFocus={event => event.stopPropagation()}
                                                control={
                                                    <CajaTexto
                                                        id={"CantidadProducto" + producto.codigoArticulo + producto.cantidad + i}
                                                        tipo={"number"}
                                                        ancho={60}
                                                        valor={producto.LinCan}
                                                        accion={handleChangeCantidad(producto.codigoProducto, i)}  // Pasamos el código del producto
                                                    />
                                                }
                                            />
                                        </TableCell>
                                        <TableCell sx={{ paddingTop: 0.2, paddingBottom: 0.2 }} component="th" scope="row">
                                            {producto.descripcionCastellano}{" (" + producto.codigoProducto + ")"}
                                        </TableCell>
                                        <TableCell sx={{ paddingTop: 0.2, paddingBottom: 0.2 }} align="right">
                                            {RedondeaPrecio(producto.precioProducto)} {" (" + (producto.precioProducto * (1 + producto.porcentajeIVA / 100)).toFixed(2) + ")"}
                                        </TableCell>
                                        <TableCell sx={{ paddingTop: 0.2, paddingBottom: 0.2 }} align="right">{producto.existenciasIniciales}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        sx={{ mb: 1, mr: 2, width: 110, height: 30 }}
                        onClick={handleAbandonar}
                    >
                        {t("MisProductos.Abandonar")}
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ mb: 1, mr: 2, width: 90, height: 30 }}
                        onClick={handleGuardar}
                    >
                        {t("MisProductos.Guardar")}
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>

    )
}

export default withTranslation()(DialogAñadirPedido)