import config from './../config/config.js'
//import React from "react";

const rutaApi = config.rutaApis + '/ficheros'

const generaPdfsAlbaranes = (envio) => {
  return fetch(rutaApi + '/generaPdfsAlbaranes/', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    //credentials: 'include',
    body: JSON.stringify(envio)
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log('Error = ', err))
}

export {
  generaPdfsAlbaranes
}