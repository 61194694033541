import config from './../config/config.js'


const rutaApi = config.rutaApis+'/auth'


const signin = (user) => {
  return fetch(rutaApi+'/signin/', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Allow-Control-Allow-Origin": 'http://localhost:3000'
        //"Access-Control-Allow-Credentials": "true"

      },
      credentials: 'include',
      body: JSON.stringify(user)
    })
    .then((response) => {
      //if (!response.ok) throw Error('Usuario no encontrado. Status: '+response.status);
      return response.json()
    })
    .catch((err) => console.log('Error = ',err))
}

const signout = () => {
  return fetch(rutaApi+'/signout/', {
    method: 'GET',
  }).then(response => {
      return response.json()
  }).catch((err) => console.log(err))
}

export {
  signin,
  signout
}
