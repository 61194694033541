import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useStore } from 'react-create-use-store';
import store from './../../adderStore.js'
import { withTranslation } from 'react-i18next'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from '@mui/material/Container';
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';

import { ThemeProvider } from '@mui/material/styles';

import ThemeGeroa from './../../ThemeGeroa.js'

import { APIProvider, Map, AdvancedMarker, Pin, InfoWindow } from '@vis.gl/react-google-maps';

import DialogCrearModificarPunto from './DialogModificarPunto.js'
import BotonXlsx from "../../components/BotonXlsx.js";
import config from './../../config/config.js'
import { BuscaIndice, EsUsuarioEspecial, FechaAaaaMmDdMmSs } from './../../config/funcionesPropias.js'

import { /*ListaPuntosProvCoor,*/ listaPuntosDenda, updatePuntoProvCoor, borrarPunto } from './../../apis/api-punto.js'
import WarningIcon from '@mui/icons-material/Warning';


const MisMapas2 = ({ t, ...props }) => {
    //const jwt = auth.isAuthenticated()
    //console.log('autenticado = ', jwt)
    const { state, actions } = useStore(store);
    const [estado, setEstado] = useState(
        {
            carga: true,
            todosPuntos: [],
            puntosAVer: [],
            lineasExcel: [],
            dialogCrearModificar: '',
            soloPuntosMios: false,
            soloPuntosCad: false,
            posCentral: { lat: 43.0745200, lng: -2.4841500 },        // Mondragon
            puntosPendientesDeRenovar: false,
            mercados: [],
            mercaPun: [],
            mercaPunHay: false,
            mercaPunEdi: []
        }
    )
    // Posicion de ENEEK y MONDRAGON. //
    //let posEneek = { lat: 43.21290376030901, lng: -2.726953625679016 }
    //const posMondragon = { lat: 43.0745200, lng: -2.4841500 }

    // Si cambia de Idioma. Recargar los tipos de Puntos. //
    useEffect(() => {
        const tiposPuntos = [
            t("MisMapas.Tipo0"),
            t("MisMapas.Tipo1"),
            t("MisMapas.Tipo2"),
            t("MisMapas.Tipo3"),
            t("MisMapas.Tipo4"),
            t("MisMapas.Tipo5"),
            t("MisMapas.Tipo6"),
            t("MisMapas.Tipo7"),
            t("MisMapas.Tipo8"),
            t("MisMapas.Tipo9"),
            t("MisMapas.Tipo10"),
            t("MisMapas.Tipo11")
        ]
        actions.cambiarTiposDePuntos(tiposPuntos)

        // Ordenar el Array de FamiliasSubfamilas por descripción. //
        let famSub = state.familiasSubfamilias
        famSub.sort((a, b) => {
            let x, y
            if (state.idioma === "Es") {
                // Descripciones Cashtellano
                x = (a.FamDesC + a.SubDesC).toUpperCase()
                y = (b.FamDesC + b.SubDesC).toUpperCase()
            } else {
                // Descripciiones Euskera
                x = (a.FamDesE + a.SubDesE).toUpperCase()
                y = (b.FamDesE + b.SubDesE).toUpperCase()
            }
            if (x < y) return -1
            if (x > y) return 1
            return 0
        })
        // Guardar el array ordenado. //
        actions.cambiarFamiliasSubfamilias(famSub)

    }, [state.idioma, state.familiasSubfamilias, actions, t])


    function cuentaPuntosProximosACaducar(lista) {
        let retorno = false
        let f1, f2
        lista.forEach((punto, i) => {
            punto.familiasMias.forEach((f, j) => {
                f1 = f.fecha.substr(6, 4) + f.fecha.substr(2, 4) + f.fecha.substr(0, 2)
                f2 = (FechaAaaaMmDdMmSs(new Date(), 30)).substr(0, 10)
                if (f1 <= f2) {
                    retorno = true
                }
            })
        })
        return retorno
    }


    const preparaVisualizacion = useCallback((data) => {
        // Reorganiza las lineas
        let tmpCodPunto = ""
        let resultado = []
        let fecha = ""
        //
        for (let i = 0; i < data.length; i++) {
            // Saltar Puntos de Venta Certificados. 
            if (data[i].PunCer === 0) {
                if (data[i].PunNum !== tmpCodPunto) {
                    tmpCodPunto = data[i].PunNum
                    resultado.push({
                        numero: data[i].PunNum,
                        titulo: data[i].PunNom,
                        productor: data[i].PunPro,
                        tipo: data[i].PunTip,
                        nota: data[i].PunNot,
                        posicion: { lat: data[i].PunLat, lng: data[i].PunLon },
                        poblacion: data[i].PunPob,
                        visible: data[i].PunVer,
                        familiasMias: [],
                        familiasAjenas: [],
                        open: false
                    })
                    /* Cambio del excel
                    resultadoExcel.push({
                        Nombre: data[i].PunNom,
                        Latitud: data[i].PunLat,
                        Longitud: data[i].PunLon,
                        Tipo: state.tiposDePuntos[data[i].PunTip],
                        nota: data[i].PunNot,
                        Productor: data[i].PunPro,
                        Familias: ""
                    })
                    */
                }

                let familia = data[i].PrPFam
                let subFami = data[i].PrPSub
                let nombreFam = ''
                let objFam = state.familiasSubfamilias.find(sub => sub.FamCod + sub.SubCod === familia + subFami);
                if (objFam) {
                    let f, s
                    if (state.idioma === "Es") {
                        f = objFam.FamDesC
                        s = objFam.SubDesC
                    } else {
                        f = objFam.FamDesE
                        s = objFam.SubDesE
                    }
                    nombreFam = f + (s === "" ? "" : " - " + s)
                }

                fecha = data[i].PrPFec !== null ? data[i].PrPFec.substr(8, 2) + "-" + data[i].PrPFec.substr(5, 2) + "-" + data[i].PrPFec.substr(0, 4) : ""
                if (data[i].PrPPro === state.codProveedor) {
                    resultado[resultado.length - 1].familiasMias.push({
                        codFamilia: familia, // data[i].PrPFam,
                        codSubFamilia: subFami, // data[i].PrPSub,
                        familia: nombreFam, // state.idioma === "Es" ? data[i].FamDesC : data[i].FamDesE,
                        fecha: fecha, // dd-mm-aaaa
                        productorFamilia: data[i].PrPPro
                    })
                } else {
                    resultado[resultado.length - 1].familiasAjenas.push({
                        codFamilia: familia, // data[i].PrPFam,
                        codSubFamilia: subFami, // data[i].PrPSub,
                        familia: nombreFam, // state.idioma === "Es" ? data[i].FamDesC : data[i].FamDesE,
                        fecha: fecha,
                        productorFamilia: data[i].PrPPro
                    })
                }
                /* Cambio del excel
                resultadoExcel[resultadoExcel.length - 1].Familias += (resultadoExcel[resultadoExcel.length - 1].Familias !== '' ? ' // ' : '') + state.idioma === "Es" ? data[i].FamDesC : data[i].FamDesE
                */
            }
        }

        // Calcular el resultado a exportar a Excel //
        let resultadoExcel = preparaExcel(resultado)
        // Calcular el punto central de puntos a ver. //
        let posC = calculaCentroDePuntos(resultado)



        //estado.todosPuntos = resultado
        //estado.lineasExcel = resultadoExcel
        // Fuerza Renderizado
        setEstado(estado => ({
            ...estado,
            todosPuntos: resultado,
            puntosAVer: resultado,
            lineasExcel: resultadoExcel,
            posCentral: posC,
            puntosPendientesDeRenovar: cuentaPuntosProximosACaducar(resultado),
        }))
    }, [state.codProveedor, state.familiasSubfamilias, state.idioma, state.tiposDePuntos])


    const preparaExcel = useCallback(puntos => {
        console.log("Datos de puntos: ", puntos)
        let Excel = []
        puntos.forEach(r => {
            // Filtrar solo los puntos cuyo productor coincida con state.codProveedor
            if (r.productor === state.codProveedor) {
                let familias = [...r.familiasMias, ...r.familiasAjenas].filter(f => f.codFamilia !== null)
    
                if (familias.length > 0) {
                    familias.forEach(f => {
                        Excel.push({
                            Nombre: r.titulo,
                            Latitud: r.posicion.lat,
                            Longitud: r.posicion.lng,
                            Tipo: r.tipo !== -1 ? state.tiposDePuntos[r.tipo] : '',
                            Nota: r.nota,
                            Productor: r.productor,
                            ProductorFamilia: f.productorFamilia,
                            Familia: f.familia,
                            HastaFecha: f.fecha
                        })
                    })
                } else {
                    Excel.push({
                        Nombre: r.titulo,
                        Latitud: r.posicion.lat,
                        Longitud: r.posicion.lng,
                        Tipo: r.tipo !== -1 ? state.tiposDePuntos[r.tipo] : '',
                        Nota: r.nota,
                        Productor: r.productor
                    })
                }
            }
        })
        return Excel
    }, [state.codProveedor, state.tiposDePuntos])


    // Contar los productos para establecer el calendario
    const loadLineasPuntosIniciales = useCallback(async () => {
        // Función vieja que sólo cargaba los puntos. //
        //await listaPuntosProvCoor({ proveedor: state.codProveedor, esProductor: state.esProductor ? "1" : "0" }).then((data) => {
        await listaPuntosDenda().then((objDatos) => {
            if (objDatos?.error) {
                console.log(objDatos.error)
            }
            // Data 1.//
            let data = objDatos?.data
            if (data?.length > 0) {
                //console.log("Puntos: ", data)
                //console.log("PuntosMer:", objDatos.data2)
                //console.log("Mercados:", objDatos.data3)

                // Todos los Mercados en Puntos. //
                /*
                let mercaPun = objDatos.data2.map(p => {
                    return {
                        'PuMNum': p.PuMNum,
                        'PuMMer': p.PuMMer
                    }
                })
                */
                // Coger los datos tal y como llegan. //
                let mercaPun = structuredClone(objDatos.data2)

                // Todos los Mercados Visibles. //
                /*
                let mercados = objDatos.data3.map(m => {
                    return {
                        'MerCod': m.MerCod,
                        'MerDesC': m.MerDesC
                    }
                })
                */
                // Coger los datos tal y como llegan (Mercod, MerDesC). //
                let mercados = structuredClone(objDatos.data3)

                setEstado(estado => ({ ...estado, mercados: mercados, mercaPun: mercaPun }))

                // Esto es solo para hacer PRUEBAS: //
                /*
                // Ver solo los puntos que tienen mercados. //
                let dataF = data.filter((d) => {
                    // Ver si en ese punto existe en mercados Puntos
                    let i = mercaPun.findIndex(x => x.PuMNum === d.PunNum)
                    return (i !== -1)
                })
                //console.log("Puntos con Mercados: ", dataF)
                preparaVisualizacionYExcel(dataF)
                */
                preparaVisualizacion(data)

                // Solo se usa en <MensajeCabecera> //
                //actions.cambiarMensaje("")

            }
        })
    }, [state.codProveedor, state.esProductor, preparaVisualizacion])

    useEffect(() => {
        if (estado.carga) {
            loadLineasPuntosIniciales()
            // Actualiza el estado sin Repintar. //
            //estado.carga = false
            setEstado(estado => ({ ...estado, carga: false }))
        }
    }, [estado.carga, loadLineasPuntosIniciales])



    function calculaCentroDePuntos(puntos) {
        let centro = { lat: 0, lng: 0 }
        let maxLat = 0
        let minLat = 90
        let maxLon = 0
        let minLon = -90

        if (puntos !== undefined) {
            for (let i = 0; i < puntos.length; i++) {
                let p = puntos[i].posicion
                // Latitud
                if (maxLat < p.lat) {
                    maxLat = p.lat
                }
                if (minLat > p.lat) {
                    minLat = p.lat
                }
                // Longitud negativas
                if (maxLon > p.lng) {
                    maxLon = p.lng
                }
                if (minLon < p.lng) {
                    minLon = p.lng
                }
            }
        }
        centro.lat = (maxLat + minLat) / 2
        centro.lng = (maxLon + minLon) / 2

        return centro
    }



    const nuevoPunto = (event) => {
        //console.log(estado.soloPuntosCad === false && estado.soloPuntosMios === false)
        if (estado.soloPuntosCad === false && estado.soloPuntosMios === false) {
            //Genera un punto
            let tp = {
                numero: undefined,
                productor: state.codProveedor,
                titulo: "",
                tipo: 0,
                poblacion: "",
                posicion: event.detail.latLng,
                nota: "",
                familiasMias: [],
                familiasAjenas: [],
                visible: 0,
                open: false
            }
            estado.dialogCrearModificar = tp

            // Datos de Mercados. //
            estado.mercaPunHay = false
            // Poner en el array de Mercados si está incluido. //
            estado.mercaPunEdi = []
            estado.mercados.forEach(m => {
                // añadir al array de mercados las marcas. //
                estado.mercaPunEdi.push({
                    'MerCod': m.MerCod,
                    'MerDesC': m.MerDesC,
                    'Incluido': false,
                    'Modificado': false
                })
            })
            tp.mercados = structuredClone(estado.mercaPunEdi)

            setEstado({ ...estado, dialogCrearModificar: tp })
        }
    }


    const modificaPunto = (p) => (event) => {
        // Añadir al punto los Mercados. //
        // ¡¡IMPORTANTE!! Hacer copia profunda (No copia referencias) //
        p.mercados = structuredClone(estado.mercaPunEdi)

        estado.dialogCrearModificar = p
        setEstado({ ...estado, dialogCrearModificar: p })
    }


    const puntoExistenteOpen = (i) => (event) => {
        let pAV = [...estado.puntosAVer]
        pAV[i].open = true
        // Punto actual. //
        //console.log("Punto actual: ", pAV[i])

        // Buscar los Mercados del Punto actual. //
        let PM = estado.mercaPun.filter(p => p.PuMNum === pAV[i].numero)
        //
        estado.mercaPunHay = PM.length > 0
        // Poner en el array de Mercados si está incluido. //
        estado.mercaPunEdi = []
        estado.mercados.forEach(m => {
            // Buscar Indice de Mercado en los Puntos. //
            let i = PM.findIndex(p => p.PuMMer === m.MerCod)
            // añadir al array de mercados las marcas. //
            estado.mercaPunEdi.push({
                'MerCod': m.MerCod,
                'MerDesC': m.MerDesC,
                'Incluido': (i > -1),
                'Modificado': false
            })
        })
        setEstado({ ...estado, puntosAVer: pAV })
    }

    const puntoExistenteClose = (i) => (event) => {
        let pAV = [...estado.puntosAVer]
        pAV[i].open = false
        // Vaciar los mercados del punto Editar. //
        estado.mercaPunEdi = []
        setEstado({ ...estado, puntosAVer: pAV })
    }


    const handleMiosTodos = (event) => {
        let tP = !estado.soloPuntosMios
        //
        let filtrados = []
        if (tP) {
            /*
            for (let i = 0; i < estado.todosPuntos.length; i++) {
                let punto = estado.todosPuntos[i]
                if (punto.familiasMias.length > 0) {
                    filtrados.push(punto)
                }
            }
            */
            filtrados = estado.todosPuntos.filter(p => (p.productor === state.codProveedor || p.familiasMias.length > 0))
        } else {
            filtrados = estado.todosPuntos
        }
        // Recalcular los puntos Excel. //
        let resultadoExcel = preparaExcel(filtrados)

        setEstado({ ...estado, soloPuntosMios: tP, puntosAVer: filtrados, lineasExcel: resultadoExcel })
    }

    const handleCadTodos = (event) => {
        let tP = estado.soloPuntosCad
        tP = !tP
        //
        let filtrados = []
        let fecha = new Date()
        let fechaDentroMes = (fecha.setMonth(fecha.getMonth() + 1))
        let tpFecha
        let tpPunto = ''
        let ubiAVer = true
        // Si queremos ver solo los pendientes de renovacion: ver los que la fecha de la familia esta a menos de 1 mes de hoy
        if (tP) {
            estado.puntosAVer.forEach((p, k) => {
                p.familiasMias.forEach((f, h) => {
                    tpFecha = (new Date(f.fecha.substr(6, 4), f.fecha.substr(3, 2) - 1, f.fecha.substr(0, 2))).getTime()
                    if (tpFecha < fechaDentroMes) {
                        if (tpPunto !== p.numero) {
                            tpPunto = p.numero
                            filtrados.push(p)
                        }
                    }
                })
            })
            ubiAVer = true
        } else {
            filtrados = estado.todosPuntos
            ubiAVer = false
        }
        setEstado({ ...estado, soloPuntosCad: tP, soloPuntosMios: ubiAVer, puntosAVer: filtrados })
        /*
        let filtrados = !estado.soloPuntosMios ? estado.todosPuntos.filter(p => {
            return p.productor === state.codProveedor
          })
          :
          estado.todosPuntos
        */
    }




    async function borraPunto(numero) {
        //console.log("Borrar: ", numero)
        // Solo permitir borrar si todas las familia son mias (pendiente????)
        await borrarPunto({ numero: numero })
            .then((data) => {
                if (data.error) {
                    console.log(data.error, data)
                }
                else {
                    // Copia de los Arrays. //
                    let pAV = [...estado.puntosAVer]
                    let tPu = [...estado.todosPuntos]
                    // Buscar el indice del punto actual. //
                    let indice = pAV.findIndex(num => num.numero === numero)
                    if (indice > -1) {
                        pAV[indice].open = false
                        pAV.splice(indice, 1);
                    }
                    // Localizar el numero en el Array de Todos los Puntos. //
                    indice = BuscaIndice(tPu, "numero", numero)
                    if (indice !== -1) {
                        tPu.splice(indice, 1)
                    }

                    // eliminar los mercados del punto borrado. //
                    let PM = estado.mercaPun.filter(p => p.PuMNum !== numero)
                    // ¡¡IMPORTANTE!! Hacer copia profunda (No copia referencias) //
                    estado.mercaPun = structuredClone(PM)

                    // Actualizar Estado. //
                    setEstado({ ...estado, puntosAVer: pAV, todosPuntos: tPu })
                }
            })
    }




    // Se usará si hay que cambiar la fecha en cada linea
    async function actualizadorPunto(modo, p) {
        //console.log("Punto: ", p)
        // Copias de los Arrays. //
        let pAVer = [...estado.puntosAVer]
        let toPun = [...estado.todosPuntos]
        let alta = false
        if (modo !== 0) {
            // Guardar el Punto
            await updatePuntoProvCoor({ proveedor: state.codProveedor, esProductor: state.esProductor ? "1" : "0", punto: p })
                .then((data) => {
                    if (data.error) {
                        console.log(data.error)
                    }
                    else {
                        //console.log("Respuesta: ", data)                        
                        if (modo === 1) {
                            // Guardar ID del Punto. //
                            if (p.numero === undefined) {
                                alta = true
                                p.numero = data.data.insertId
                            }
                            // Guardar Normal
                            let tpFamMias = p.nuevasFamilias.filter((f) => {
                                return f.Activa
                            })
                            // Traslada las que nos sirven
                            p.familiasMias = []
                            tpFamMias.forEach(f => {
                                // Convertir Fecha aaaa-mm-dd a dd-mm-aaaa
                                let fecha = f.Fecha.substr(8, 2) + "-" + f.Fecha.substr(5, 2) + "-" + f.Fecha.substr(0, 4)
                                p.familiasMias.push({
                                    codFamilia: f.Codigo.slice(0, 3), // f.Codigo,
                                    codSubFamilia: f.Codigo.slice(3),
                                    familia: f.Nombre,
                                    fecha: fecha, // dd-mm-aaaa
                                    productorFamilia: state.codProveedor
                                })
                            })
                        } else if (modo === 2) {
                            // Solo Publicar
                        } else if (modo === 3) {
                            // Solo Despublicar
                        }
                        //estado.dialogCrearModificar = ""
                        //
                        let numero = p.numero
                        // Busca el punto, si existe lo actualiza, si no lo añade
                        // Si es alta
                        if (alta) {
                            // añadir punto nuevo a los arrays. //
                            toPun.push(p)
                            pAVer.push(p)
                        } else {
                            // Modificar el Punto en Todos los Puntos. //
                            let indice = BuscaIndice(toPun, "numero", p.numero)
                            // Si lo encuentra //
                            if (indice !== -1) {
                                // Sustituir el punto por el dato modificado. //
                                //    toPun.splice(indice, 1, p)
                                toPun[indice] = p
                            }
                            // Modificar el Punto en los Puntos Actuales. //
                            indice = BuscaIndice(pAVer, "numero", p.numero)
                            // Si lo encuentra //
                            if (indice !== -1) {
                                // Sustituir el punto por el dato modificado. //
                                pAVer[indice] = p
                            }
                            // eliminar los mercados del punto modificado. //
                            let PM = estado.mercaPun.filter(p => p.PuMNum !== numero)
                            // ¡¡IMPORTANTE!! Hacer copia profunda (No copia referencias) //
                            estado.mercaPun = structuredClone(PM)
                        }

                        // Modifica la visualizacion del punto Actual. //
                        let PM = []
                        // Añadir los mercados del Punto. //
                        p.mercados.forEach(m => {
                            if (m.Incluido) {
                                estado.mercaPun.push({
                                    'PuMNum': numero,
                                    'PuMMer': m.MerCod
                                })
                            }
                        })
                        // ¡¡IMPORTANTE!! Hacer copia profunda (No copia referencias) //
                        estado.mercaPunEdi = structuredClone(p.mercados)
                    }
                })
        }
        // Tanto si se guarda como si no
        setEstado({ ...estado, dialogCrearModificar: "", puntosAVer: pAVer, todosPuntos: toPun })
    }


    // Borrar la Ubicacion
    const handleClickBorrarUbicacion = (numero) => (event) => {
        if (confirm("¿Eliminar el punto actual?") === true) {
            borraPunto(numero)
        }
    }


    const soltamosPunto = (n) => (event) => {
        let tP = estado.todosPuntos
        tP[n].posicion = event.latLng
        setEstado({ ...estado, todosPuntos: tP })
        // Hay que salvar en la base de datos
    }



    function colorRotulo(p, t) {
        let colorFinal = ''
        if (EsUsuarioEspecial(state.emailUsuario) !== -1) {
            // Es Eneek o Geroa
            colorFinal = 'rojo'
        } else if (p.productor === state.codProveedor) {
            colorFinal = 'verde'
        } else {
            colorFinal = 'naranja'
        }
        colorFinal += t
        //
        return colorFinal
    }


    function desabilitadoBorrar(p) {
        let desabilitado = false
        // Si no es usuario especial. //
        if (EsUsuarioEspecial(state.emailUsuario) === -1) {
            if (p.productor !== state.codProveedor) {
                desabilitado = true
            } else if (p.familiasAjenas.length > 0) {
                desabilitado = true
                if (p.familiasAjenas[0].codFamilia === null) {
                    desabilitado = false
                }
            }
        }
        return desabilitado
    }



    const ampliacionPunto = (p, n) => (
        <Fragment key={"P_" + p.numero}>
            <Grid container>
                <Grid item xs={8}>
                    <Typography variant={colorRotulo(p, '18')}>
                        {p.titulo}
                    </Typography>
                    <br />
                    <Typography variant={colorRotulo(p, '15')}>
                        {p.poblacion}
                    </Typography>
                    <br />
                    <Typography variant="gris15">
                        {state.tiposDePuntos[p.tipo]}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        startIcon={<EditIcon />}
                        sx={{ mt: 1, mr: 1, width: 100, height: 26 }}
                        onClick={modificaPunto(p)}
                    >
                        {t("MisMapas.Editar")}
                    </Button>
                    <br />
                    <Button
                        variant="contained"
                        disabled={desabilitadoBorrar(p)}
                        startIcon={<DeleteForeverIcon />}
                        sx={{ mt: 0.5, mr: 1, mb: 1, width: 100, height: 26, bgcolor: ThemeGeroa.palette.purpura.main, color: ThemeGeroa.palette.blanco.main }}
                        onClick={handleClickBorrarUbicacion(p.numero)}
                    >
                        {t("MisMapas.Borrar")}
                    </Button>
                </Grid>
            </Grid>
            <Typography variant="gris13">
                {p.nota}
            </Typography>
            {estado.mercaPunHay && (
                <div>
                    <Divider sx={{ mb: 1 }} />
                    <Typography variant="azul16">
                        {t("MisMapas.Mercados") + ":"}
                    </Typography>
                    {estado.mercaPunEdi.map(m => m.Incluido && (
                        <div key={"mi" + m.MerCod} >
                            <Typography sx={{ ml: 2, mr: 1 }} variant="verde13">
                                {m.MerDesC}
                            </Typography>
                            <br />
                        </div>
                    ))}
                </div>
            )}
            <Divider sx={{ mb: 1 }} />
            {(p.familiasMias.length !== 0 || p.familiasAjenas.length !== 0) &&
                <Typography variant="azul16">
                    {t("MisMapas.TiposProducto") + ":"}
                </Typography>
            }
            <br />
            {p.familiasMias.length !== 0 &&
                p.familiasMias.map((f, i) => (
                    <Grid key={"fp" + i} container sx={{ mb: 1.5, mt: i === 0 ? 1 : -1 }}>
                        <Box sx={{ width: 8 / 12 }}>
                            <Typography noWrap={true} sx={{ ml: 2, mr: 1 }} variant={f.productorFamilia === state.codProveedor ? "verde13" : "naranja11"}>
                                {/*f.codFamilia !== null ? f.familia + " (" + f.fecha.substr(8, 2) + f.fecha.substr(4, 4) + f.fecha.substr(0, 4) + ")" : ""*/}
                                {f.codFamilia !== null ? f.familia + " (" + f.fecha + ")" : ""}
                            </Typography>
                        </Box>
                        <br />
                    </Grid>
                ))
            }
            {p.familiasAjenas.length !== 0 &&
                p.familiasAjenas.map((f, i) => (
                    (BuscaIndice(p.familiasMias, 'codFamilia', f.codFamilia) === -1 &&
                        <Grid key={"fa" + i} container sx={{ mb: 1.5, mt: i === 0 ? 1 : -1 }}>
                            <Box sx={{ width: 8 / 12 }}>
                                <Typography noWrap={true} sx={{ ml: 2, mr: 1 }} variant={f.productorFamilia === state.codProveedor ? "verde13" : "naranja11"}>
                                    {f.codFamilia !== null ? f.familia : ""}
                                </Typography>
                            </Box>
                            <br />
                        </Grid>
                    )
                ))
            }
        </Fragment >
    )




    function colorDelPunto(p) {
        let color
        // Esta a medias de definir AMARILLO
        if (p.visible === 0) {
            color = "yellow"
        } else if (p.productor === state.codProveedor) {
            color = ThemeGeroa.palette.primary.main
            //  color = "blue"
        } else {
            color = "#ff6333"
        }
        return color
    }

    function colorDelGlyph(p) {
        let color
        // Esta a medias de definir ROJO
        if (p.visible === 0) {
            color = "white"
        } else if (p.productor === state.codProveedor) {
            color = "white"
        } else {
            color = "white"
        }
        return color
    }

    function colorDelBorde(p) {
        let color
        // Esta a medias de definir AMARILLO
        if (p.visible === 0) {
            color = "yellow"
        } else if (p.productor === state.codProveedor) {
            color = ThemeGeroa.palette.primary.main
        } else {
            color = "orange"
        }
        return color
    }



    const listaMapa = (
        <Grid sx={{ border: "1px solid green", height: "92%", width: "100%" }}>
            <Fragment>
                <APIProvider apiKey={config.passMapas}>
                    <Map
                        onClick={nuevoPunto}
                        zoom={9}
                        //center={posEneek}
                        center={estado.posCentral}
                        mapId={'MiMapa'}
                        loading={"async"}
                    >
                        {estado.puntosAVer.map((p, i) => (
                            <Fragment key={"k1_" + i}>
                                {(p.productor === state.codProveedor) && (p.visible === 0)
                                    ?
                                    <AdvancedMarker
                                        key={"k2_" + i}
                                        position={p.posicion}
                                        draggable={true}
                                        onClick={puntoExistenteOpen(i)}
                                        onDragEnd={soltamosPunto(i)}
                                    >
                                        {/*<PuntoMapa punto={p} />*/}

                                        <Pin
                                            background={colorDelPunto(p)}
                                            borderColor={colorDelBorde(p)}
                                            glyphColor={colorDelGlyph(p)}
                                        >
                                        </Pin>
                                    </AdvancedMarker>
                                    :
                                    <AdvancedMarker
                                        key={"k2_" + i}
                                        position={p.posicion}
                                        draggable={false}
                                        onClick={puntoExistenteOpen(i)}
                                    //onDragEnd={soltamosPunto(i)}
                                    >
                                        {/*<PuntoMapa punto={p} />*/}

                                        <Pin
                                            background={colorDelPunto(p)}
                                            borderColor={colorDelBorde(p)}
                                            glyphColor={colorDelGlyph(p)}
                                        >
                                        </Pin>
                                    </AdvancedMarker>
                                }
                                {p.open && <InfoWindow
                                    key={"k3_" + i}
                                    position={p.posicion}
                                    minWidth={400}
                                    onCloseClick={puntoExistenteClose(i)}
                                >
                                    {ampliacionPunto(p, i)}
                                </InfoWindow>}
                            </Fragment>
                        ))}
                    </Map>
                </APIProvider>
            </Fragment>
        </Grid>
    )

    const tituloYFiltros = (
        <Box sx={{ display: 'flex' }} height="50px">
            <Box sx={{ width: 5 / 6 }}>
                <Typography variant="verde30">
                    {t("Mapas.Mapa")}
                </Typography>
            </Box>
            {estado.puntosPendientesDeRenovar && <Button
                variant="contained"
                startIcon={!estado.soloPuntosCad ? <WarningIcon /> : ''}
                sx={{
                    mt: 1.1, mr: 2, height: 30,
                    // Para que quepan los textos en Euskera. //
                    minWidth: state.idioma !== "Es" ? 290 : 270
                }}
                onClick={handleCadTodos}
            >
                {!estado.soloPuntosCad ? t("Mapas.ACaducar") : t("Mapas.TodasUbicaciones")}
            </Button>
            }
            <Button
                variant="contained"
                //startIcon={<SendIcon />}
                sx={{
                    mt: 1.1, height: 30,
                    // Para que quepan los textos en Euskera. //
                    minWidth: state.idioma !== "Es" ? 140 : 120
                }}
                onClick={handleMiosTodos}
            >
                {!estado.soloPuntosMios ? t("Mapas.MisUbicaciones") : t("Mapas.TodasUbicaciones")}
            </Button>
            <Box sx={{ mt: 2, ml: 2 }}>
                <BotonXlsx data={estado.lineasExcel} filename="puntosMapa" />
            </Box>
        </Box>
    )

    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Container sx={{ mt: 1.5, height: "90vh", width: "80vw" }}>
                {tituloYFiltros}
                {estado.dialogCrearModificar !== '' &&
                    <DialogCrearModificarPunto
                        punto={estado.dialogCrearModificar}
                        numeroPunto={1}
                        actualizador={actualizadorPunto}
                    ></DialogCrearModificarPunto>
                }
                {listaMapa}
                <br />
            </Container>
        </ThemeProvider>
    );
}

export default withTranslation()(MisMapas2)

